import React, { useState, useEffect } from 'react';
import TimezoneSelect from 'react-timezone-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AdAccountForm = () => {
    const token = localStorage.getItem('token');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        status: '',
        currency: '',
        timezone: '',
        country: '',
        supplier_fee: '',
        start_date: new Date(),
        bm_id: '',
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };
    
    const timezoneChangeHandler = (event: any) => {
        setFormData({
            ...formData,
            ["timezone"]: event.value
        });
    };

    const startDateChangeHandler = (date: any) => {
        setFormData({
            ...formData,
            ["start_date"]: date,
        });
    };
 
    const submitHandler = async (e: any) => {
        e.preventDefault();
        if(token) { 
            try {
                const response = await fetch(`${process.env.REACT_APP_API_ADMIN_URL}/createAdAccount`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
                    body: JSON.stringify(formData)
                }
            );

                if (response.ok) {
                    setSuccess(true);
                    setError(false);
                } else {
                    setSuccess(false);
                    setError(true);
                }
            } catch (error) {
            console.error('Error:', error);
            }
        }
    };

    return (
        <form onSubmit={submitHandler}>
            <label className='white-title'>ID</label>
            <input type="text" name="id" value={formData.id} onChange={handleChange}/>
            <label className='white-title'>Status</label>
            <select className="form-select" value={formData.status} name="status" onChange={handleChange} placeholder="status">
                <option disabled value="">Choose Status</option>
                <option value="active">Active</option>
                <option value="deactivated">Deactivated</option>
                <option value="missing">Missing</option>
            </select>
            <label className='white-title'>Currency</label>
            <select className="form-select" value={formData.currency} name="currency" onChange={handleChange} placeholder="type">
                <option disabled value="">Choose Currency</option>
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
            </select>
            <label className='white-title'>Timezone</label>
            <TimezoneSelect
                name="timezone"
                value={formData.timezone}
                onChange={event => timezoneChangeHandler(event)}
            /> 
            <label className='white-title'>Country</label>
            <input type="text" name="country" value={formData.country} onChange={handleChange}/>
            <label className='white-title'>Supplier Fee</label>
            <select className="form-select" value={formData.supplier_fee} name="supplier_fee" onChange={handleChange} placeholder="supplier fee">
                <option disabled value="">Choose Supplier Fee</option>
                <option value="0.0125">0.0125</option>
                <option value="0.01">0.01</option>
            </select>
            <label className='white-title'>Name</label>
            <input type="text" name="name" value={formData.name} onChange={handleChange}/>
            <label className='white-title'>Start Date </label>
            <br/>
            <DatePicker selected={formData.start_date} onChange={(date: any) => startDateChangeHandler(date)} />
            <br/>
            <label className='white-title'>Supplier BM-ID</label>
            <input type="text" name="bm_id" value={formData.bm_id} onChange={handleChange}/>
            <button type="submit" className="btn btn-primary btn-large btn-block">Add New Ad Account</button>
            <br/>
            {error &&
                <div className="alert alert-danger" role="alert">
                    Error: Failed to save Ad Account
                </div>
            }
            {success &&
                <div className="alert alert-success" role="alert">
                    Success: Saved Ad Account
                </div>
            }
        </form>
    );
};

export default AdAccountForm;