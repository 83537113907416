import React, { useState, useEffect } from 'react';
import Pagination from '../Pagination';

const AdAccountAdSpends = (props: {account: any}) => {
    const [adSpends, setAdSpends] = useState([{}]);
    const token = localStorage.getItem('token');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Adjust based on your preference

    useEffect(() => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/getAccountAdSpends/${props.account.ad_account_id}`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            ).then(response => response.json())
            .then(data => {
                if(data) {
                    setAdSpends(data)
                }
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            })

        }
    }, [props.account]);

    // Calculate the current invoices to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = adSpends.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages and generate an array of page numbers
    const totalPages = Math.ceil(adSpends.length / itemsPerPage);
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);


    return (
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Day</th>
                            <th>Spend</th>
                            <th>Reach</th>
                            <th>Impressions</th>
                            <th>Frequency</th>
                            <th>Currency</th>
                            <th>Attribute Setting</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems ?
                            currentItems.map((record: any) => (
                                <tr key={record.id}>
                                    <td>{record.day}</td>
                                    <td>{record.spend}</td>
                                    <td>{record.reach}</td>
                                    <td>{record.impressions}</td>
                                    <td>{record.frequency}</td>
                                    <td>{record.currency}</td>
                                    <td>{record.attribute_settings}</td>
                                </tr>
                            )) : 
                            <></>
                        }

                    </tbody>
                </table>
                <Pagination pageNumbers={pageNumbers} currentPage={currentPage} paginate={paginate}/>
            </div>
    );
};

export default AdAccountAdSpends;