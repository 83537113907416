import React, {useState, createContext, useContext} from "react";

export const UserContext = createContext();

export const ContextProvider = props => {
    const [token, setToken] = useState('');
    const [client, setClient] = useState();
    const [role, setRole] = useState();
    return <UserContext.Provider value={[token, setToken, client, setClient, role, setRole]} {...props} ></UserContext.Provider>;
};

export const useContextStore = () => useContext(UserContext);

