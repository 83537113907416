import React, {useState, SyntheticEvent} from 'react';

function CsvUpload(){
	const [selectedFile, setSelectedFile] = useState();
    const token = localStorage.getItem('token');
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);


	const changeHandler = (event: any) => {

		setSelectedFile(event.target.files[0]);

	};


	const handleSubmission = async (e: SyntheticEvent) => {
        e.preventDefault();

        const formData = new FormData();
        if (selectedFile) {
		    formData.append('file', selectedFile);
            const response = await fetch(
    			`${process.env.REACT_APP_API_ADMIN_URL}/uploadCsv`,
    	    	{
    	    		method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`},
		    		body: formData,
					//credentials: "include"
        		}
    	    )
                .then((response) => response.json())
    		    .then((result) => {
    				console.log('Success:', result);
					setError(false);
					setSuccess(true);
    			})
    			.catch((error) => {
    				console.error('Error uploading csv:', error);
					setError(true);
					setSuccess(false);
    			});
        }
    };


	return(
   		<div className="col-lg-12">
			<h3>Upload Daily Ad Spend CSV</h3>
			<input type="file" name="file" onChange={changeHandler} />
			<div>
				<button className="btn btn-primary btn-block btn-large" onClick={handleSubmission}>Submit</button>
				<br/>
				{error &&
					<div className="alert alert-danger" role="alert">
						Error: Failed to upload spends 
					</div>
				}
				{success &&
					<div className="alert alert-success" role="alert">
						Success: Uploaded spends
					</div>
				}
			</div>
		</div>
	)

}

export default CsvUpload;