import React, { useState, useEffect } from 'react';
import TimezoneSelect from 'react-timezone-select'

const AddAdAccount = () => {
    const [selectedTimezone, setSelectedTimezone] =useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const [bmId, setBmId] = useState("");
    const [type, setType] = useState("");
    const [currency, setCurrency] = useState("");
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
    const client = localStorage.getItem('client');
    const token = localStorage.getItem('token');

    const changeHandler = (event: any) => {
        setSelectedTimezone(event.value);
    }

    const submitHandler = () => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/createAdAccountRequest`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`},
                    body: JSON.stringify({
                        'bm_id': bmId,
                        'type': type,
                        'currency': currency,
                        'client_id': client,
                        'timezone': selectedTimezone
                    })
                }
            ).then((response) => {
                setSuccess(true);
                setError(false);
            })
    		.catch((error) => {
				setError(true);
				setSuccess(false);
 			});
        }

    }
    
    useEffect(() => {
    }) 

    return (
        <div className=''>
                    <label className='white-title'>BM-ID</label>
                    <input type="text" name="bm-id" onChange={event => setBmId(event.target.value)}/>
                    <label className='white-title'>Type </label>
                    <select className="form-select" value={type} name="type" placeholder="type" onChange={event => setType(event.target.value)}>
                        <option disabled value="">Choose Type</option>
                        <option value="TOPUP">TOPUP</option>
                        <option value="CC">Credit Card</option>
                    </select>
                    <label className='white-title'>Currency </label>
                    <select className="form-select" value={currency} name="currency" onChange={event => setCurrency(event.target.value)} placeholder="type">
                        <option disabled value="">Choose Currency</option>
                        <option value="EUR">EUR</option>
                        <option value="USD">USD</option>
                    </select>
                <label className='white-title'>Timezone </label>
                <TimezoneSelect
                    value={selectedTimezone}
                    onChange={event => changeHandler(event)}
                /> 
                <br/>
                <button className="btn btn-primary btn-large btn-block" onClick={submitHandler}>Request New Account</button>
                <br/>
                {error &&
                    <div className="alert alert-danger" role="alert">
                        Error: Failed to submit request
                    </div>
                }
                {success &&
                    <div className="alert alert-success" role="alert">
                        Success: Request Submitted
                    </div>
                }
        </div>
    );
};

export default AddAdAccount;