import React from 'react';
import './App.css';
import Login from "./pages/Login";
import {BrowserRouter, Route} from "react-router-dom";
import AdminNav from "./components/admin/AdminNav";
import AdminHome from "./pages/admin/Home";
import AdminInvoices from "./pages/admin/Invoices";
import UserHome from "./pages/user/UserHome";
import { ContextProvider } from './UserContext';
import AdminAdSpends from './pages/admin/AdSpends';
import Clients from './pages/admin/Clients';
import MaluCreditCards from './pages/admin/MaluCreditCards';
import AdminAdAccounts from './pages/admin/AdAccounts';
import AdminAdAccountRequests from './pages/admin/AdAccountRequests';
import UserAdAccounts from './pages/user/AdAccounts';
import UserNav from './components/user/UserNav';
import UserInvoices from './pages/user/UserInvoices';
import Users from './pages/admin/Users';

function App() {

    return (
        <div className="App portal">
            <BrowserRouter>
                <ContextProvider>
                    <Route path={["/", "/login"]} exact component={() => <Login />}/>
                    <Route path="/admin/home" exact component={() => <><AdminNav/><AdminHome /></>}/>
                    <Route path="/admin/users" exact component={() => <><AdminNav/><Users /></>}/>
                    <Route path="/admin/invoices" exact component={() => <><AdminNav/><AdminInvoices /></>}/>
                    <Route path="/admin/adspends" exact component={() => <><AdminNav/><AdminAdSpends /></>}/>
                    <Route path="/admin/clients" exact component={() => <><AdminNav/><Clients /></>}/>
                    <Route path="/admin/credit-cards" exact component={() => <><AdminNav/><MaluCreditCards /></>}/>
                    <Route path="/admin/ad-accounts" exact component={() => <><AdminNav/><AdminAdAccounts /></>}/>
                    <Route path="/admin/ad-account-requests" exact component={() => <><AdminNav/><AdminAdAccountRequests /></>}/>
                    <Route path="/user/home" exact component={() => <><UserNav/><UserHome /></>}/>
                    <Route path="/user/invoices" exact component={() => <><UserNav/><UserInvoices /></>}/>
                    <Route path="/user/ad-accounts" exact component={() => <><UserNav/><UserAdAccounts /></>}/>
                </ContextProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
