import React, { useState, useEffect } from 'react';

// ad account dropdown component
// client_id - 0 returns accounts not assigned to any client i.e. available
const AdAccountDropdown = (props: {adAccount: string, setAdAccount: any, client: any}) => {
    const [adAccounts, setAdAccounts] = useState([{}]);
    const [refresh, setRefresh] = useState(false);
    const token = localStorage.getItem('token');
    
    // get ad accounts
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/${props.client == 0 ? "admin/getAvailableAdAccounts" : `getAdAccounts/${props.client}`}`,
        {
            method: 'GET',
            headers: {'Authorization': `Bearer ${token}`},
        }
        ).then(response => response.json())
        .then(data => {
            if(data) {
                setAdAccounts(data)
            }
        }).catch((error) => {
            console.error('Error fetching data: ', error);
        })
        if(refresh) {
            setRefresh(false);
        }
    }, [token, refresh]);

    const changeAdAccount = (value: any) => {
        props.setAdAccount(value);
    }

    return(
        <select className="form-select"  name="ad_account_id" placeholder="type" onChange={event => changeAdAccount(event.target.value)} >
            <option disabled selected value="">Choose Ad Account</option>
            {
                adAccounts?.map((record: any) => (
                    record.ad_account_id === props.adAccount ?
                        <option selected value={record.ad_account_id}>{record.name}</option>
                        :
                        <option value={record.ad_account_id}>{record.name} | {record.currency} | {record.timezone}</option>
                ))
            
            }
        </select>
    )
};

export default AdAccountDropdown;