import React, { useState, useEffect } from 'react';
import User from '../../components/admin/User';
import UserForm from '../../components/admin/UserForm';
import UsersTable from '../../components/admin/UsersTable';

const Users = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [selected, setSelected] = useState("0");
    
    const goBack = () => {
        setSelected("0")
    }


    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }) 

    return (
        <>
            {loggedIn ?
                <div>
                    <main className="main justify-content-center portal">
                            {selected === "0" ? 
                                <>
                                    <div className="container">
                                        <UserForm/>
                                    </div>
                                    <div className="container">
                                        <UsersTable setSelected={setSelected}/>
                                    </div>
                                </>
                                :
                                <div className="container">
                                    <div >
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><button className="btn" onClick={goBack}>Back</button></li>
                                            </ol>
                                        </nav>
                                    </div>  
                                    <User user={selected} goBack={goBack} />
                                </div>
                            }
                    </main>
                </div>
                :
                <div>You are not logged in.</div>
            }
        </>
    );
};

export default Users;