import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const RefundModal = ( props: {show: boolean, setRefundAmount: any, handleClose: any, handleSubmit: any}) => {

  const updateAmount = () => {
    const amount = (document.getElementById(`refund-amount`) as HTMLInputElement).value;
    props.setRefundAmount(amount);
  }

  return (
    <Modal show={props.show} onHide={props.handleClose} className="portal">
      <Modal.Header closeButton>
        <Modal.Title>Refund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>Amount </label> 
        <input id="refund-amount" type="number" onChange={updateAmount}/>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger"onClick={props.handleClose}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={props.handleSubmit}>
          Submit 
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default RefundModal;