import React, { useState, useEffect } from 'react';
import formatDate from '../../util/date';

const Invoice = (props: {invoice: any, goBack: any}) => {
    const token = localStorage.getItem('token');
    const [topups, setTopups] = useState([{}]);
    const [itemsTitle, setItemsTitle] = useState("Items");
    const [itemIDTitle, setItemIDTitle] = useState("");
    const [itemDescTitle, setItemDescTitle] = useState("");
    const [transactionID, setTransactionID] = useState(props.invoice.transaction_id);
    const [updated, setUpdated] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/getInvoiceItems/${props.invoice.invoice_id}`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            )
            .then(response => response.json())
                .then(data => {
                    if(data){
                        setTopups(data)
                        
                    }
                })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            })


        }
        if(props.invoice.type === "TOPUP") {
            setItemsTitle("Topups");
            setItemIDTitle("Ad Account ID");
            setItemDescTitle("Account Name");
        }
        if(props.invoice.type === "MANUAL"){
            setItemsTitle("Items");
            setItemIDTitle("ID");
            setItemDescTitle("Description");
        }
        if(props.invoice.type === "CC") {
            setItemsTitle("Accounts");
            setItemIDTitle("Ad Account ID");
            setItemDescTitle("Ad Name");
        }
    }, [props.invoice]);

    const downloadInvoice = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/downloadInvoice/${props.invoice.invoice_id}`,
            {
                method: "POST",
                headers: { Authorization: `Bearer ${token}` },
            }
        ).then((response) => {
                response.blob().then((blob) => {
             
                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);
                     
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = `invoice_${props.invoice.invoice_id}.pdf`;
                alink.click();
                })
            }).catch((error) => {
                console.error('Error posting data: ', error);
            });
    }

    const deleteInvoice = () => {
        fetch(`${process.env.REACT_APP_API_ADMIN_URL}/deleteInvoice/${props.invoice.invoice_id}`,
            {
                method: "POST",
                headers: { Authorization: `Bearer ${token}` },
            }
        ).then((response) => {
                props.goBack()
            }).catch((error) => {
                console.error('Error posting data: ', error);
            });
    }

    const transactionIDChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTransactionID(e.target.value);
        if (e.target.value !== props.invoice.transaction_id || updated === false) {
            setUpdated(true);
        } else {
            setUpdated(false);
        }
    }

    const updateTransactionID = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/invoiceArrived/${props.invoice.invoice_id}/${transactionID}`,
            {
                method: 'GET',
                headers: {'Authorization': `Bearer ${token}`},
            }
        ).then((response) => {
            if(response.ok) {
                setSuccess(true);
                setError(false);
            }
        }).catch((error) => {
            console.error('Error fetching data: ', error);
            setSuccess(false);
            setError(true);
        })
    };

    return (
        <div>
            <div className="col-12">
                <div>
                    <h6 className='white-title'>ID: {props.invoice.invoice_id}</h6>
                    <h6 className='white-title'>Type: {props.invoice.type}</h6>
                    <h6 className='white-title'>Date: {formatDate(props.invoice.invoice_date)}</h6>
                    {props.invoice.type === "CC" ? <h6 className='white-title'>Payment Term: {props.invoice.payment_term}</h6> :<></>}
                    <h6 className="white-title">Client Name: {props.invoice.client_name}</h6>
                    {props.invoice.type === "TOPUP" && 
                        <div>
                            <label className='white-title'>Transaction ID</label>
                            <input type="text" name="transaction_id" value={transactionID} onChange={transactionIDChangeHandler}/>
                            <button className="btn btn-success btn-medium btn-block" disabled={!updated} onClick={updateTransactionID}>Save</button> 
                            <br/>
                            {error &&
                                <div className="alert alert-danger" role="alert">
                                    Error: Failed to update 
                                </div>
                            }
                            {success &&
                                <div className="alert alert-success" role="alert">
                                    Success: Updated invoice
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="col-12">
                <h4 className="white-title">{itemsTitle}</h4>
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>{itemIDTitle}</th>
                            <th>{itemDescTitle}</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {topups ?
                            topups.map((record: any, index: any) => (
                                <tr>
                                    <td>{props.invoice.type === "MANUAL" ? index + 1 : record.ad_account_id}</td>
                                    <td>{props.invoice.type === "MANUAL" ? record.description : record.name}</td>
                                    <td>{record.amount?.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                                </tr>
                            ))
                            :
                            <>No data retrieved</>
                        }
                    </tbody>
                </table>
            </div>
			<button className="btn btn-primary btn-block btn-large" onClick={downloadInvoice}>Download PDF</button>
            <br/>
			<button className="btn btn-danger btn-block btn-large" onClick={deleteInvoice}>Delete Invoice</button>
        </div>
    );
};

export default Invoice;