import React, { useEffect, useState } from 'react';
import InvoicesTable from '../../components/user/InvoicesTable';
import Invoice from '../../components/user/Invoice';

const UserInvoices = () => {
    const [invoiceData, setInvoiceData] = useState([{}]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState("0");
    const token = localStorage.getItem('token');


    const goBack = () => {
        setSelected("0")
    }

    return(
        <div className="main justify-content-center">
            <div>
            {selected === "0" ?
            <>
                <div className='container'>
                    <InvoicesTable setSelected={setSelected}/>
                </div>
            </>
            :
                <div className="container">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><button className="btn" onClick={goBack}>Back</button></li>
                            </ol>
                        </nav>
                    </div>  
                    <Invoice invoice={selected} />
                </div>
            }
            </div>
        </div>

    )
}

export default UserInvoices;