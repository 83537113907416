import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CCInvoiceModal from './CCInvoiceModal';
import ClientDropdown from './ClientDropdown';

interface InvoiceItem {
    description: string;
    amount: number; // or number, depending on how you handle the input
}

interface FormData {
    invoice_date: Date;
    client_id: number;
    currency: string;
    items: InvoiceItem[];
}

const InvoiceForm = () => {
    const token = localStorage.getItem('token');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        invoice_date: new Date(),
        client_id: 0,
        currency: '',
        items: [] // Explicitly an array of InvoiceItem objects
    });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    
    const handleItemChange = (e: any, index: any) => {
        const { name, value } = e.target;
        const items: any = [...formData.items];
        items[index][name] = value;
        setFormData({ ...formData, items });
    };  

    const handleAddItem = () => {
        const newItem: InvoiceItem = { description: '', amount: 0 }; // Initialize with empty values
        setFormData({
            ...formData,
            items: [...formData.items, newItem] // Add the new item to the existing array
        });
    };

    const handleRemoveItem = (index: any) => {
        const items = [...formData.items];
        items.splice(index, 1);
        setFormData({ ...formData, items });
    };

    const startDateChangeHandler = (date: any) => {
        setFormData({
            ...formData,
            invoice_date: date,
        });
    };

    const setClient = (value: any) => {
        var clientId = parseInt(value)
        setFormData({
            ...formData,
            client_id: clientId,
        });
    };

    const submitHandler = async (e: any) => {
        e.preventDefault();
        if(token) { 
            try {
                const response = await fetch(`${process.env.REACT_APP_API_ADMIN_URL}/createInvoice`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
                    body: JSON.stringify(formData)
                }
            );

                if (response.ok) {
                    setSuccess(true);
                    setError(false);
                } else {
                    setSuccess(false);
                    setError(true);
                }
            } catch (error) {
            console.error('Error:', error);
            }
        }
    };
    
    const initiateCCGeneration = () => {
        handleShow();
    }
    
    return (
        <>
            <form onSubmit={submitHandler}>
                <label className='white-title'>Client</label>
                <ClientDropdown client={formData.client_id} setClient={setClient}/>
                <label className='white-title'>Currency</label>
                <select className="form-select" value={formData.currency} name="currency" onChange={handleChange} placeholder="type">
                    <option disabled value="">Choose Currency</option>
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                </select>
                <br/>
                <label className='white-title'>Invoice Date</label>
                <DatePicker selected={formData.invoice_date} onChange={(date: any) => startDateChangeHandler(date)} />
                <br/>
                <div>
                    <h4 className='white-title'>Invoice Items</h4>
                    {formData.items.map((item: any, index: any) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <label className='white-title' style={{ marginRight: '10px' }}>Description:</label>
                            <input 
                                type="text" 
                                name="description" 
                                value={item.description} 
                                onChange={(e) => handleItemChange(e, index)} 
                                style={{ marginRight: '10px' }}
                            />
                            <label className='white-title' style={{ marginRight: '10px' }}>Amount:</label>
                            <input 
                                type="number" 
                                name="amount" 
                                value={item.amount} 
                                onChange={(e) => handleItemChange(e, index)} 
                                style={{ marginRight: '10px' }}
                            />
                            <button type="button" className='btn btn-danger' onClick={() => handleRemoveItem(index)}>Remove</button>
                        </div>
                    ))}
                </div>
                <button type="button" className="btn btn-success" onClick={handleAddItem}>Add Item</button>
                <br/>
                <br/>
                <button type="submit" className="btn btn-primary btn-large btn-block">Create New Invoice</button>
                <br/>
                {error &&
                    <div className="alert alert-danger" role="alert">
                        Error: Failed to save Invoice 
                    </div>
                }
                {success &&
                    <div className="alert alert-success" role="alert">
                        Success: Saved Invoice
                    </div>
                }
            </form>
            <br/>
            <button className="btn btn-secondary btn-large btn-block" onClick={event => initiateCCGeneration()}>Generate CC Invoices</button>
            <CCInvoiceModal show={show} handleClose={handleClose}/>
        </>
    );
};

export default InvoiceForm;