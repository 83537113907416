import React, { useState, useEffect } from 'react';
import formatDate from '../../util/date';
import Pagination from '../Pagination';

const DailyTotalAdSpendsTable = () => {
    const token = localStorage.getItem('token');
    const [spends, setSpends] = useState([]);    
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Adjust based on your preference
    type SortConfig = {
        key: keyof any | null;
        direction: 'ascending' | 'descending';
    };
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/getDailyTotalAdSpends`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`},
                    body: JSON.stringify({ "order": "desc", "start_date": "", "end_date": "" }), // Convert struct to JSON
                }
            )
            .then(response => response.json())
                .then(data => {
                    if(data){
                        setSpends(data);
                        setFilteredData(data);
                    }
                })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            })


        }
    }, [token]);

    useEffect(() => {
        let newData = [...spends];

        // Sort
        if (sortConfig.key !== null && spends.length > 0) {
            newData.sort((a, b) => {
                const aValue = a[sortConfig.key as keyof typeof a];
                const bValue = b[sortConfig.key as keyof typeof b];

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        // Search
        newData = newData.filter(
            (item: any) =>
                item.currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.day.toString().includes(searchQuery) ||
                item.daily_total.toString().includes(searchQuery)

        );

        setFilteredData(newData);
    }, [spends, sortConfig, searchQuery]);

    const requestSort = (key: keyof any) => { // Replace 'ClientData' with your data type
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    } 

    // Calculate the current invoices to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages and generate an array of page numbers
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

    return (
        <div className="container page-body">
            <h3>Daily Total Ad Spends</h3>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <table id="datatable" className="table table-hover" >
                <thead>
                        <tr>
                            <th onClick={() => requestSort('day')}>Day</th>
                            <th onClick={() => requestSort('currency')}>Currency</th>
                            <th onClick={() => requestSort('daily_total')}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems ?
                            currentItems.map((record: any) => (
                                <tr key={record.currency}>
                                    <td>{record.day}</td>
                                    <td>{record.currency}</td>
                                    <td>{record.daily_total?.toLocaleString('de-DE', {currency: record.currency !== "-" ? record.currency : "EUR", minimumFractionDigits: 2 })}</td>
                                </tr>
                            )) 
                        : <>No data retrieved</>}
                    </tbody>
            </table>
            <Pagination pageNumbers={pageNumbers} currentPage={currentPage} paginate={paginate}/>
        </div>

    );
};

export default DailyTotalAdSpendsTable;