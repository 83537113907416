import React, { useState, useEffect } from 'react';

const AdAccount = (props: {account: any}) => {
    const [adSpends, setAdSpends] = useState([{}]);
    const token = localStorage.getItem('token');
    useEffect(() => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/getAccountAdSpends/${props.account.ad_account_id}`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            ).then(response => response.json())
            .then(data => {
                if(data) {
                    setAdSpends(data)
                }
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            })

        }
    }, [props.account]);


    return (
        <div className='table-responsive'>
                    <h3>Ad Account ID: {props.account.ad_account_id}</h3>
                    <h5 className="white-title">Spend</h5>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Day</th>
                                <th>Spend</th>
                                <th>Reach</th>
                                <th>Impressions</th>
                                <th>Frequency</th>
                                <th>Currency</th>
                                <th>Attribute Setting</th>
                            </tr>
                        </thead>
                        <tbody>
                            {adSpends ?
                                adSpends.map((record: any) => (
                                    <tr key={record.id}>
                                        <td>{record.day}</td>
                                        <td>{record.spend?.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                                        <td>{record.reach}</td>
                                        <td>{record.impressions}</td>
                                        <td>{record.frequency}</td>
                                        <td>{record.currency}</td>
                                        <td>{record.attribute_settings}</td>
                                    </tr>
                                )) : 
                                <></>
                            }

                        </tbody>
                    </table>
        </div>
    );
};

export default AdAccount;