import React, { useState, useEffect } from 'react';
import formatDate from '../../util/date';
import Pagination from '../Pagination';
import AdAccountHistoryEditModal from './AdAccountHistoryEditModal';

const AdAccountHistory = (props: {account: any}) => {
    const [items, setItems] = useState([{}]);
    const token = localStorage.getItem('token');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Adjust based on your preference
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [edit, setEdit] = useState({})
    useEffect(() => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/getAdAccountHistory/${props.account.ad_account_id}`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            ).then(response => response.json())
            .then(data => {
                if(data) {
                    setItems(data)
                }
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            })

        }
    }, [props.account, show]);

    // Calculate the current invoices to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages and generate an array of page numbers
    const totalPages = Math.ceil(items.length / itemsPerPage);
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

    const initiateHistoryEdit = (record: any) => {
        setEdit(record);
        handleShow();
    }


    return (
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th>Type</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems ?
                            currentItems.map((record: any, index: number) => (
                                <tr key={index}>
                                    <td>{record.client_name}</td>
                                    <td>{record.type}</td>
                                    <td>{formatDate(record.start_date)}</td>
                                    <td>{record.end_date >= "0002-01-01" && formatDate(record.end_date)}</td>
                                    <td>
                                        <button className="btn btn-primary" onClick={event => initiateHistoryEdit(record)}>edit</button>
                                    </td>
                                </tr>
                            )) : 
                            <></>
                        }

                    </tbody>
                </table>
                <Pagination pageNumbers={pageNumbers} currentPage={currentPage} paginate={paginate}/>
                <AdAccountHistoryEditModal show={show} handleClose={handleClose} handleSubmit={()=> {}} record={edit} />
            </div>
    );
};

export default AdAccountHistory;