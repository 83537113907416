import React, { useState, useEffect } from 'react';
import AdAccountsTable from '../../components/admin/AdAccountsTable';
import AdAccount from '../../components/admin/AdAccount';
import AdAccountForm from '../../components/admin/AdAccountForm';
import '../../App.css';

const AdAccounts = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [selected, setSelected] = useState("0");
    const [display, setDisplay] = useState("table");

    const clearSelected = () => {
        setSelected("0");
    }

    const toggleTable = () => {
        if (display === "table") {
            setDisplay("form");
        } else {
            setDisplay("table");
        }
    }



    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }) 

    const goBack = () => {
        setSelected("0")
    }

    return (
        <>
            {loggedIn ?
                <div>
                    <main className="main justify-content-center">
                            {selected === "0" ? 
                                <div className="container">
                                    <h3>Ad Accounts <button className="btn btn-primary btn-medium" onClick={toggleTable}>{display === "table" || display === "available" || display === "unused" ? "+" : "-"}</button> </h3>
                                    {display === "table" &&
                                        <AdAccountsTable setSelected={setSelected}/>
                                    }
                                    {display === "form" &&
                                        <AdAccountForm />
                                    }
                                </div>
                                :
                                <div className="container">
                                    <div>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><button className="btn" onClick={goBack}>Back</button></li>
                                            </ol>
                                        </nav>
                                    </div>  
                                    <AdAccount account={selected} />
                                </div>
                            }
                            
                    </main>
                </div>
                :
                <div>You are not logged in.</div>
            }
        </>
    );
};

export default AdAccounts;