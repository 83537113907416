import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination';

const AdAccountsTable = (props: {setSelected: any}) => {
    const [adAccountsData, setAdAccountsData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const client = localStorage.getItem('client');
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Adjust based on your preference
    const [filter, setFilter] = useState("");
    type SortConfig = {
        key: keyof any | null;
        direction: 'ascending' | 'descending';
    };
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');

    // Calculate the current invoices to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentInvoices = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages and generate an array of page numbers
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

    useEffect(() => {
        if(token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_BASE_URL}/getAdAccounts/${client}`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            ).then(response => response.json())
            .then(data => {
                if(data) {
                    setAdAccountsData(data)
                }
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            })
            if(refresh) {
                setRefresh(false);
            }
        }
    }, [token, refresh]);
    
    useEffect(() => {
        let newData = [...adAccountsData];

        // Sort
        if (sortConfig.key !== null && adAccountsData.length > 0) {
            newData.sort((a, b) => {
                const aValue = a[sortConfig.key as keyof typeof a];
                const bValue = b[sortConfig.key as keyof typeof b];

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        // Search
        newData = newData.filter(
            (item: any) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.status.toString().includes(searchQuery) ||
                item.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.timezone.toString().includes(searchQuery) ||
                item.ad_account_id.toString().includes(searchQuery)
        );

        setFilteredData(newData);
    }, [adAccountsData, sortConfig, searchQuery]);

    const requestSort = (key: keyof any) => { // Replace 'ClientData' with your data type
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    } 

    return(
        <div >
            <h3>Ad Accounts</h3>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className='table-responsive'>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('ad_account_id')}>ID</th>
                        <th onClick={() => requestSort('name')}>Name</th>
                        <th onClick={() => requestSort('status')}>Status</th>
                        <th onClick={() => requestSort('currency')}>Currency</th>
                        <th onClick={() => requestSort('timezone')}>Timezone</th>
                        <th onClick={() => requestSort('country')}>Country</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData ?
                        filteredData.map((record: any) => (
                            <tr key={record.ad_account_id}>
                                <td><button className="btn" onClick={event => props.setSelected(record)}>{record.ad_account_id}</button></td>
                                <td>{record.name}</td>
                                <td>{record.status}</td>
                                <td>{record.currency}</td>
                                <td>{record.timezone}</td>
                                <td>{record.country}</td>
                            </tr>
                        )) : 
                        <></>
                    }
                </tbody>
            </table>
            </div>
            <Pagination pageNumbers={pageNumbers} currentPage={currentPage} paginate={paginate}/>
        </div>
    )

}

export default AdAccountsTable;