import React, { useState, useEffect } from 'react';
import AdAccountDropdown from "./AdAccountDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ClientRemoveAdAccount = (props: {clientId: any}) => {
    const [adAccount, setAdAccount] = useState({ ad_account_id: "0", start_date: "" });
    const [endDate, setEndDate] = useState(() => {
        const now = new Date();
        return new Date(now.setHours(0, 0, 0, 0));
    });
    const token = localStorage.getItem('token');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const submitHandler = () => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/removeAdAccountFromClient`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`},
                    body: JSON.stringify({
                        'ad_account_id': adAccount,
                        'client_id': props.clientId,
                        'end_date': endDate,
                    })
                }
            ).then(() => {
                setSuccess(true);
                setError(false);
            }).catch(() => {
                setError(true);
                setSuccess(false);
            })
        }
    }
    
    const handleDateChange = (date: Date) => {
        // Set the time to 00:00:00.000
        const newDate = new Date(date.setHours(0, 0, 0, 0));
        setEndDate(newDate);
    };

    return(
        <div>
            <h4 className="white-title">Remove Ad Account</h4>
            <AdAccountDropdown adAccount={adAccount.ad_account_id} setAdAccount={setAdAccount} client={props.clientId}/>
            <br/>
            <label className='white-title'>End Date </label>
            <br/>
            <DatePicker selected={endDate} onChange={(date: Date) => handleDateChange(date)} />
            <br/>
            <button className="btn btn-danger btn-large" onClick={submitHandler}>Remove Account</button>
			<br/>
			{error &&
				<div className="alert alert-danger" role="alert">
					Error: Failed to remove ad account from client
				</div>
			}
			{success &&
				<div className="alert alert-success" role="alert">
					Success: Removed ad account from client
				</div>
			}
        </div>
    );
}

export default ClientRemoveAdAccount;