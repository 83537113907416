import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'; // Import Spinner from react-bootstrap

const CCInvoiceModal = (props: { show: boolean, handleClose: any }) => {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const token = localStorage.getItem('token');
    const defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 14);
    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() - 1);

    const [startDate, setStartDate] = useState(defaultStartDate.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(defaultEndDate.toISOString().split('T')[0]);

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target)
        setStartDate(e.target.value);
        console.log(startDate);
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(e.target.value);
    };

    const generateCCInvoices = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true); // Set loading state to true

        const payload = {
            start_date: startDate,
            end_date: endDate
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_ADMIN_URL}/updateAccountWeekSpend`,
                {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                setSuccess(false);
                setError(true);
                throw new Error("Failed to update view");
            }

            await response.json();
            setError(false);
            setSuccess(true);
        } catch (error) {
            setSuccess(false);
            setError(true);
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_ADMIN_URL}/generateCCInvoices`,
                {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                setError(true);
                setSuccess(false);
                throw new Error("Failed to generate invoices");
            }

            await response.json();
            setError(false);
            setSuccess(true);
        } catch (error) {
            setError(true);
            setSuccess(false);
        }

        setLoading(false); // Set loading state to false after completion
    };

    return (
        <Modal show={props.show} onHide={props.handleClose} className="portal">
        <Modal.Header closeButton>
            <Modal.Title>Generate CC Invoices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="alert alert-warning" role="alert">
                Important: Submission sends emails with Invoices to all CC clients
            </div>
            <form onSubmit={generateCCInvoices}>
            <label>Start Date </label>
            <input type="date" value={startDate} onChange={handleStartDateChange} />
            <br />
            <label>End Date </label>
            <input type="date" value={endDate} onChange={handleEndDateChange} />
            <Modal.Footer>
                {error &&
                    <div className="alert alert-danger" role="alert">
                        Error generating Invoices
                    </div>
                }
                {success &&
                    <div className="alert alert-success" role="alert">
                        Success generating invoices
                    </div>
                }
                <button className="btn btn-danger" onClick={props.handleClose}>
                Cancel
                </button>
                <button type="submit" className="btn btn-primary" disabled={loading}>
                {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                </button>
            </Modal.Footer>
            </form>
        </Modal.Body>
        </Modal>
    );
};

export default CCInvoiceModal;
