import React, { useState, useEffect } from 'react';
import ClientMonthlySpendRankings from '../../components/admin/ClientMonthlySpendRankings';
import DailyTotalAdSpendsBarChart from '../../components/admin/DailyTotalAdSpendsBarChart';
import RemainingFundsChart from '../../components/admin/RemainingFundsChart';

const AdminHome = () => {
    const [loggedIn, setLoggedIn] = useState(false);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }) 


    return (
        <>
            {loggedIn ?
                <div>
                    <main className="main d-flex justify-content-center">
                        <div className="container">
                            <DailyTotalAdSpendsBarChart/>
                            <RemainingFundsChart/>
                            <ClientMonthlySpendRankings/>
                        </div>
                    </main>
                </div>
                :
                <div>You are not logged in.</div>
            }
        </>
    );
};

export default AdminHome;
