import React, { useState, useEffect } from 'react';
import AdAccountRequestsTable from '../../components/admin/AdAccountRequestsTable';
import AdAccountRequest from '../../components/admin/AdAccountRequest';

const AdAccountRequests = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [selected, setSelected] = useState("0");

    const clearSelected = () => {
        setSelected("0");
    }


    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }) 

    const goBack = () => {
        setSelected("0")
    }

    return (
        <>
            {loggedIn ?
                <div>
                    <main className="main justify-content-center">
                            {selected === "0" ? 
                                <div className="container">
                                    <AdAccountRequestsTable setSelected={setSelected}/>
                                </div>
                                :
                                //<Client client={selected} setSelectedClient={clearSelected}/>
                                <div className="container">
                                    <div>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><button className="btn" onClick={goBack}>Back</button></li>
                                            </ol>
                                        </nav>
                                    </div>  
                                    <AdAccountRequest request={selected} />
                                </div>
                            }
                            
                    </main>
                </div>
                :
                <div>You are not logged in.</div>
            }
        </>
    );
};

export default AdAccountRequests;