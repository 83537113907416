import '../../App.css';
import './TopupAccounts.css';
import React, { useEffect, useState } from 'react';


function TopupAccounts(){
    const token = localStorage.getItem('token');
    const client = localStorage.getItem('client');
    const [topupData, setTopupData] = useState([{
        "ad_account_id": 0,
        "name": "-", 
        "currency": "-",
        "total_topups": 0,
        "total_spend": 0, 
    }]);
    const [topupFormData, setTopupFormData] = useState([{ amount: 0, client_id: client, currency: "" }]);
    const [loading, setLoading] = useState(false);
    const [topupsTotal, setTopupsTotal] = useState(0);
    const [topupsSpendTotal, setTopupsSpendTotal] = useState(0);
    const [totalsData, setTotalsData] = useState([{ total_topups: 0, total_spend: 0, cc_limit: 0}]);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        if(token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_BASE_URL}/getTopupAccountsOverview/${client}`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            )
                .then(response => response.json())
                .then(data => {
                    if(data){ 
                        setTopupData(data)
                    }
                })
                .finally(() => { 
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching data: ', error);
                })
        }
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getClientTotalTopups/${client}`,
            {
                method: 'GET',
                headers: {'Authorization': `Bearer ${token}`},
            }
        ).then(response => response.json())
        .then(data => {
            if(data) {
                setTotalsData(data)
            }
        }).catch((error) => {
            console.error('Error fetching data: ', error);
        })

    }, [token, client]);

    const handleChange = (event: any, currency: string) => {
        const updatedFormData = [...topupFormData];
        // check if record for ad_account_id exists
        const record: any = updatedFormData.find((cur: any) => cur.currency === currency);
        if (record) {
            // if exists -> update spend
            record.amount = event.target.value;
        } else {
            // else add new record with spend
            // if length 1
            if (updatedFormData.length === 1 && updatedFormData.at(0)?.currency === "") {    
                setTopupFormData(
                    [
                        { amount: event.target.value, client_id: client, currency: currency }
                    ]
                );
            } else {
                setTopupFormData(
                    [
                        ...updatedFormData,
                        { amount: event.target.value, client_id: client, currency: currency }
                    ]
                );
            }
        }
    }

    const submitHandler = (event: any) => {
        event.preventDefault();
        let valid = false;
        // validate data
        topupFormData.forEach((value: any) => {
            if (value.amount > 0) {
                // invalid
                valid = true;
            } 

        });
        if (valid) {
            // valid -> send data to backend
            fetch(`${process.env.REACT_APP_API_BASE_URL}/postTopups/${client}`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`},
                    body: JSON.stringify(topupFormData)
                }
            )
            .then(response => response.json())
            .then(data => {
                if(data){
                    setInvoices(data)
                    setSuccess(true);
                    setError(false);
                } else {
                    setError(true);
                    setErrorMsg("Error creating invoice")
                    setSuccess(false);
                }
            }).catch((err) => {
                setError(true);
                setSuccess(false);
                console.error('Error posting data: ', err);
            });

        } else {
            setError(true);
            setErrorMsg("Invalid Input: Enter an amount greater than 0")
        }
    }

    const downloadInvoice = (id: string) => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/downloadInvoice/${id}`,
            {
                method: "POST",
                headers: { Authorization: `Bearer ${token}` },
            }
        ).then((response) => {
                response.blob().then((blob) => {
             
                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);
                     
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = `invoice_${id}.pdf`;
                alink.click();
                })
            }).catch((error) => {
                console.error('Error posting data: ', error);
            });
    }

	return(
        <div className="with-footer table-responsive-md">
            {loading ? (

                <div>Loading...</div>

            ) : (
            <>
            <h1 className="white-title">Topup Accounts</h1>
            <form>
            <table className="with-footer table table-hover">
                <thead>
                    <tr>
                        <th>Ad Account</th>
                        <th>Currency</th>
                        <th>Ad Spend</th>
                    </tr>
                </thead>
                <tbody>
                    {topupData ?
                    topupData.map((record: any) => (
                    <tr key={record.ad_account_id}>
                        <td>{record.name}</td>
                        <td>{record.currency}</td>
                        <td>{record.total_spend.toLocaleString('de-DE', {currency: record.currency !== "-" ? record.currency : "EUR", minimumFractionDigits: 2 })}</td>
                    </tr>
                    )) :
                    <></>}
                </tbody>
                <tfoot>
                    {totalsData ?
                        <>
                            <tr>
                                {totalsData.length === 1 ? 
                                    <td colSpan={2}></td>
                                    :
                                    <td></td>
                                }
                                {totalsData.map((record: any) => (
                                    <td><b className="table-focus">{record.currency}</b></td>
                                ))}
                            </tr> 
                            <tr>
                                {totalsData.length === 1 ? 
                                    <td colSpan={2}><b>Topup:</b></td>
                                    :
                                    <td><b>Topup:</b></td>
                                }
                                {totalsData.map((record: any) => (
                                    <td><b>{record.total_topups.toLocaleString('de-DE', { minimumFractionDigits: 2})}</b></td>
                                ))}
                            </tr> 
                            <tr>
                                {totalsData.length === 1 ? 
                                    <td colSpan={2}><b>Ad Spend:</b></td>
                                    :
                                    <td><b>Ad Spend:</b></td>
                                }
                                {totalsData.map((record: any) => (
                                    <td><b>{record.total_spend.toLocaleString('de-DE', { minimumFractionDigits: 2})}</b></td>
                                ))}
                            </tr>
                            <tr>
                                {totalsData.length === 1 ? 
                                    <td colSpan={2}><b>Remaining:</b></td>
                                    :
                                    <td><b>Remaining:</b></td>
                                }
                                {totalsData.map((record: any) => (
                                    <td><b>{(record.total_topups - record.total_spend).toLocaleString('de-DE', { minimumFractionDigits: 2})}</b></td>
                                ))}
                            </tr>
                            {totalsData.map((record: any) => (
                                (record.currency_status === 1 ? 
                                    <tr>
                                        <td colSpan={4}>
                                            <div>
                                                <input type="number" min={200} onChange={event => handleChange(event, record.currency)} placeholder={`Enter ${record.currency} topup amount`}/>                                    
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    <></>
                                )
                            ))}
                        </>
                        :
                        <></>
                    }
                </tfoot>
            </table>
            {error &&
                <div className="alert alert-danger" role="alert">
                    Error: Failed to generate Topup Invoices
                </div>
            }
            {success &&
                <div className="alert alert-success" role="alert">
                    Success: Generated Topup Invoices
                </div>
            }
            <button className="btn btn-primary btn-large btn-block" onClick={submitHandler}>Generate topup invoice</button>
            <div className="topup-response-area">
                {invoices ?
                    invoices.map((id: any) => (
                            <button className="btn btn-secondary btn-large topup-response-area-btn" onClick={event => downloadInvoice(id)}>Download Invoice: ID {id}</button>
                    ))
                    : <></>
                } 
            </div>
            </form>
            </>)}
		</div>
	)

}

export default TopupAccounts;