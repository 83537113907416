import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// If you're using images or icons, ensure they are imported correctly.
import logo from '../../assets/malu_logo.jpeg';


const UserNav = () => {
    const logout = async () => {
        localStorage.clear();
        await fetch(`${process.env.REACT_APP_API_AUTH_URL}/logout`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        });
    };

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" className="portal-nav">
            <Container>
                <Navbar.Brand as={Link} to="/user/home">
                    <img src={logo} alt="Logo" width="30" height="30" className="d-inline-block align-top portal-nav-brand" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/user/home">Home</Nav.Link>
                        <Nav.Link as={Link} to="/user/invoices">Invoices</Nav.Link>
                        <Nav.Link as={Link} to="/user/ad-accounts">Ad Accounts</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link as={Link} to="/login" onClick={logout}>Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default UserNav;

