import React, { useState, useEffect } from 'react';
import Client from '../../components/admin/Client';
import ClientsTable from '../../components/admin/ClientsTable';
import ClientForm from '../../components/admin/ClientForm';
import '../../App.css';

const AdminClients = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [selected, setSelected] = useState("0");
    const [table, setTable] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }) 

    const goBack = () => {
        setSelected("0")
    }

    const toggleTable = () => {
        setTable(!table);
    }

    return (
        <>
            {loggedIn ?
                <div>
                    <main className="main justify-content-center">
                            {selected === "0" ? 
                                <div className="container">
                                    <h3>Clients <button className="btn btn-primary btn-medium" onClick={toggleTable}>{table ? "+" : "-"}</button></h3>
                                    {table ?
                                        <ClientsTable setSelected={setSelected}/>
                                        :
                                        <ClientForm client={null}/>
                                    }
                                </div>
                                :
                                <div className="container">
                                    <div >
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><button className="btn" onClick={goBack}>Back</button></li>
                                            </ol>
                                        </nav>
                                    </div>  
                                    <Client client={selected} />
                                </div>
                            }
                    </main>
                </div>
                :
                <div>You are not logged in.</div>
            }
        </>
    );
};

export default AdminClients;