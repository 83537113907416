import React, { useState, useEffect } from 'react';
import Pagination from '../Pagination';
import AdAccountAdSpends from './AdAccountAdSpends';
import AdAccountHistory from './AdAccountHistory';

const AdAccount = (props: {account: any}) => {
    const token = localStorage.getItem('token');
    const [status, setStatus] = useState(props.account.status);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    
    const submitHandler = async (e: any) => {
        e.preventDefault();
        if(token) { 
            try {
                const response = await fetch(`${process.env.REACT_APP_API_ADMIN_URL}/updateAdAccountStatus`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
                    body: JSON.stringify({
                        'ad_account_id': props.account.ad_account_id,
                        'status': status
                    })
                }
            );

                if (response.ok) {
                    setSuccess(true);
                    setError(false);
                } else {
                    setSuccess(false);
                    setError(true);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    
    const handleChange = (e: any) => {
        setStatus(e.target.value);
    };

    return (
        <div className="col-12">
                <div>
                    <h3>Ad Account ID: {props.account.ad_account_id}</h3>
                    <form onSubmit={submitHandler}>
                        <label className='white-title'>Status</label>
                        <select className="form-select" value={status} name="status" onChange={handleChange} placeholder="status">
                            <option disabled value="">Choose Status</option>
                            <option value="active">Active</option>
                            <option value="deactivated">Deactivated</option>
                            <option value="missing">Missing</option>
                        </select>
                        <br/>
                        <button type="submit" className="btn btn-primary btn-block btn-large">Update Status</button>
                        <br/>
                        {error &&
                            <div className="alert alert-danger" role="alert">
                                Error: Failed to update status
                            </div>
                        }
                        {success &&
                            <div className="alert alert-success" role="alert">
                                Success: Updated status
                            </div>
                        }
                    </form>
                    <h5 className="white-title">History</h5>
                    <AdAccountHistory account={props.account}/> 
                    <h5 className="white-title">Spend</h5>
                    <AdAccountAdSpends account={props.account}/> 
                </div>
        </div>
    );
};

export default AdAccount;