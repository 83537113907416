import React, { useEffect, useState } from 'react';
import Invoice from '../../components/admin/Invoice';
import InvoicesTable from '../../components/admin/InvoicesTable';
import InvoiceForm from '../../components/admin/InvoiceForm';

function AdminInvoices() {
    const [invoiceData, setInvoiceData] = useState([{}]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState("0");
    const [display, setDisplay] = useState("table");
    const token = localStorage.getItem('token');

    const toggleTable = () => {
        if (display === "table") {
            setDisplay("form");
        } else {
            setDisplay("table");
        }
    }

    const goBack = () => {
        setSelected("0")
    }

    return(
        <div className="main justify-content-center">
            <div>
            {selected === "0" ?
                <div className="container">
                    <h3>Invoices <button className="btn btn-primary btn-medium" onClick={toggleTable}>{display === "table" ? "+" : "-"}</button> </h3>
                    {display === "table" &&
                        <InvoicesTable setSelected={setSelected}/>
                    }
                    {display === "form" &&
                        <InvoiceForm />
                    }
                </div>
            :
                <div className="container">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><button className="btn" onClick={goBack}>Back</button></li>
                            </ol>
                        </nav>
                    </div>  
                    <Invoice invoice={selected} goBack={goBack}/>
                </div>
            }
            </div>
        </div>

    )
}

export default AdminInvoices;