import React, { useState, useEffect } from 'react';
import MaluCreditCardsTable from '../../components/admin/MaluCreditCardsTable';
import MaluCreditCardForm from '../../components/admin/MaluCreditCardForm';

const MaluCreditCards = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [selected, setSelected] = useState("0");
    const [table, setTable] = useState(true);

    const clearSelected = () => {
        setSelected("0");
    }


    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }) 

    const toggleTable = () => {
        setTable(!table);
    }

    return (
        <>
            {loggedIn ?
                <div>
                    <main className="main justify-content-center">
                            {selected === "0" ? 
                                <div className="container">
                                    <h3>Credit Cards <button className="btn btn-primary btn-medium" onClick={toggleTable}>{table ? "+" : "-"}</button></h3>
                                    {table ?
                                        <MaluCreditCardsTable setSelected={setSelected}/>
                                        :
                                        <MaluCreditCardForm/>
                                    }
                                </div>
                                :
                                //<Client client={selected} setSelectedClient={clearSelected}/>
                                <>Under construction</>
                            }
                    </main>
                </div>
                :
                <div>You are not logged in.</div>
            }
        </>
    );
};

export default MaluCreditCards;