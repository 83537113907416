import React from 'react';

const Pagination = (props: { pageNumbers: any, currentPage: any, paginate: any }) => {
  const totalPages = props.pageNumbers.length;
  let displayedPages = [];

  if (totalPages <= 4) {
    displayedPages = props.pageNumbers;
  } else {
    if (props.currentPage <= 2) {
      displayedPages = [1, 2, 3, '...', totalPages];
    } else if (props.currentPage >= totalPages - 1) {
      displayedPages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
    } else {
      displayedPages = [
        1,
        '...',
        props.currentPage - 1,
        props.currentPage,
        props.currentPage + 1,
        '...',
        totalPages
      ];
    }
  }

  return (
    <div className="mt-3">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {displayedPages.map((number: any, index: any) => (
            <li key={index} className={`page-item ${props.currentPage === number ? 'active' : ''}`}>
              {number === '...' ? (
                <span className="page-link">...</span>
              ) : (
                <button className="page-link" onClick={() => props.paginate(number)}>
                  {number}
                </button>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
