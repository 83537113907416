import React, {SyntheticEvent, useState} from 'react';
import {Redirect} from "react-router-dom";
import logo from '../assets/malu_login_logo.png';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('/login');
    const [error, setError] = useState('');
    //const [token, setToken, client, setClient, role, setRole] = useContextStore();

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const response = await fetch(`${process.env.REACT_APP_API_AUTH_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            //credentials: 'include',
            body: JSON.stringify({
                email,
                password
            })
        });

        const content = await response.json();
        if (content.error) {
            setError(content.error);
        }
        
        if (content.role === 1) {
            setRedirectUrl("/admin/home");
            setRedirect(true);
        } else if (content.role === 2){
            setRedirectUrl("/user/home");
            setRedirect(true);
        }
        localStorage.clear();

        //setToken(content.token);
        localStorage.setItem( 'token', content.token );
        //setRole(content.role);
        localStorage.setItem( 'role', content.role);
        //setClient(content.client);
        localStorage.setItem( 'client', content.client);
    }

    if (redirect) {
        return <Redirect to={redirectUrl}/>;
    }

    return (
        <div className="login-container">
            <div className="login">
                <form onSubmit={submit} className="form-signin">
                    <div className="logo-container">
                        <img className="login-logo" src={logo}></img>
                    </div>
                    <h1 className="h3 mb-3 fw-normal">Login</h1>
                    <input type="email" className="form-control" placeholder="email" required
                        onChange={e => setEmail(e.target.value)}
                    />

                    <input type="password" className="form-control" placeholder="password" required
                        onChange={e => setPassword(e.target.value)}
                    />

                    {error !== "" ?
                        <h5 className="white-title center">{error}</h5>
                        :
                        <></>
                    }
                    <button type="submit" className="btn btn-primary btn-block btn-large">Let me in.</button>
                </form> 
            </div>
        </div>
    );
};

export default Login;
