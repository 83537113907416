import { logRoles } from '@testing-library/react';
import React, { useState, useEffect } from 'react';
import ClientDropdown from './ClientDropdown';

const UserForm = () => {
    const token = localStorage.getItem('token');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(1);
    const [client, setClient] = useState(0);
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const submitHandler = () => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/registerUser`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`},
                    body: JSON.stringify({
                        'email': email,
                        'client_id': client,
                        'role_id': role,
                        'password': password
                    })
                }
            ).then(() => {
                setSuccess(true);
                setError(false);
            }).catch(() => {
                setError(true);
                setSuccess(false);
            })
        }

    }
    
    useEffect(() => {
    }) 

    const changeRole = (value: string) => {
        var roleId = parseInt(value)
        setRole(roleId);
    }

    return (
        <div className=''>
            <label className='white-title'>Email</label>
            <input type="text" name="email" onChange={event => setEmail(event.target.value)}/>
            <label className='white-title'>Role </label>
            <select className="form-select"  name="role_id" placeholder="type" onChange={event => changeRole(event.target.value)} >
                <option value="1">Admin</option>
                <option value="2">Client</option>
            </select>
            <label className='white-title'>Client</label>
            <ClientDropdown client={client} setClient={setClient}/>
            <label className='white-title'>Password</label>
            <input type="text" name="password" onChange={event => setPassword(event.target.value)}/>
            <button className="btn btn-primary btn-large btn-block" onClick={submitHandler}>Add New User</button>
			<h4 className="white-title">{success ? "Created new user" : ""}{error ? "Failed to create user": ""}</h4>
            {error &&
                <div className="alert alert-danger" role="alert">
                    Error: Failed to save user
                </div>
            }
            {success &&
                <div className="alert alert-success" role="alert">
                    Success: Saved user
                </div>
            }
        </div>
    );
};

export default UserForm;