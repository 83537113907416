import React, { useState, useEffect } from 'react';

const ClientForm = (props: { client: any}) => {
    const token = localStorage.getItem('token');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState(props.client ? 
        {
            client_id: props.client.ID,
            name: props.client.name,
            country: props.client.country,
            town: props.client.town,
            plz: props.client.plz,
            street: props.client.street,
            company_name: props.client.company_name,
            commission: props.client.commission.toString(),
            email: props.client.email,
        }
        : 
        {
        name: '',
        country: '',
        town: '',
        plz: '',
        street: '',
        company_name: '',
        commission: '',
        email: '',
        }
    );

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const submitHandler = async (e: any) => {
        e.preventDefault();
        // Assuming you have an API endpoint to handle the POST request
        if(token) { 
            try {
                const response = await fetch(`${process.env.REACT_APP_API_ADMIN_URL}/${props.client ? "updateClient" : "createClient"}`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
                    body: JSON.stringify(formData)
                }
            );

                if (response.ok) {
                    setSuccess(true);
                    setError(false);
                } else {
                    setSuccess(false);
                    setError(true);
                }
            } catch (error) {
            console.error('Error:', error);
            }
        }
    };
    
    useEffect(() => {
    }) 

    return (
        <form onSubmit={submitHandler}>
            <label className='white-title'>Name</label>
            <input type="text" name="name" value={formData.name} onChange={handleChange}/>
            <label className='white-title'>Email</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange}/>
            <label className='white-title'>Company Name</label>
            <input type="text" name="company_name" value={formData.company_name} onChange={handleChange}/>
            <label className='white-title'>Street</label>
            <input type="text" name="street" value={formData.street} onChange={handleChange}/>
            <label className='white-title'>Postcode</label>
            <input type="text" name="plz" value={formData.plz} onChange={handleChange}/>
            <label className='white-title'>Town</label>
            <input type="text" name="town" value={formData.town} onChange={handleChange}/>
            <label className='white-title'>Country</label>
            <input type="text" name="country" value={formData.country} onChange={handleChange}/>
            <label className='white-title'>Commission %</label>
            <input type="number" name="commission" value={formData.commission} onChange={handleChange}/>
            <button type="submit" className="btn btn-primary btn-large btn-block">Save Client</button>
			<h4 className="white-title">{success ? "Saved Client" : ""}{error ? "Failed to save Client": ""}</h4>
			<br/>
			{error &&
				<div className="alert alert-danger" role="alert">
					Error: Failed to save Client
				</div>
			}
			{success &&
				<div className="alert alert-success" role="alert">
					Success: Saved Client
				</div>
			}
        </form>
    );
};

export default ClientForm;