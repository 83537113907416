import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination';

const ClientsTable = (props: { setSelected: any }) => {
    const [clientsData, setClientsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage, setClientsPerPage] = useState(10); // Adjust based on your preference
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    type SortConfig = {
        key: keyof any | null;
        direction: 'ascending' | 'descending';
    };
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/getClients`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setClientsData(data);
                        setFilteredData(data);
                    }
                }).catch((error) => {
                    console.error('Error fetching data: ', error);
                })
            if (refresh) {
                setRefresh(false);
            }
        }
    }, [token, refresh]);

    useEffect(() => {
        let newData = [...clientsData];

        // Sort
        if (sortConfig.key !== null && clientsData.length > 0) {
            newData.sort((a, b) => {
                const aValue = a[sortConfig.key as keyof typeof a];
                const bValue = b[sortConfig.key as keyof typeof b];

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        // Search
        newData = newData.filter(
            (item: any) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.street.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.town.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.plz.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.bank.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.commission.toString().includes(searchQuery)

        );

        setFilteredData(newData);
    }, [clientsData, sortConfig, searchQuery]);


    const requestSort = (key: keyof any) => { // Replace 'ClientData' with your data type
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    } 

    const selectClient = (client: any) => {
        props.setSelected(client);
    }

    // Calculate the current invoices to display
    const indexOfLastClient = currentPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClients = filteredData.slice(indexOfFirstClient, indexOfLastClient);

    // Calculate total pages and generate an array of page numbers
    const totalPages = Math.ceil(filteredData.length / clientsPerPage);
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

    return (
        <div>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="table-responsive">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('name')}>Name</th>
                        <th onClick={() => requestSort('email')}>Email</th>
                        <th onClick={() => requestSort('company_name')}>Company Name</th>
                        <th onClick={() => requestSort('street')}>Street</th>
                        <th onClick={() => requestSort('town')}>Town</th>
                        <th onClick={() => requestSort('plz')}>Postcode</th>
                        <th onClick={() => requestSort('country')}>Country</th>
                        <th onClick={() => requestSort('commission')}>Commission</th>
                    </tr>
                </thead>
                <tbody>
                    {currentClients.map((record: any) => (
                        <tr key={record.id}>
                            <td><button className="btn" onClick={event => selectClient(record)}>{record.name}</button></td>
                            <td>{record.email}</td>
                            <td>{record.company_name}</td>
                            <td>{record.street}</td>
                            <td>{record.town}</td>
                            <td>{record.plz}</td>
                            <td>{record.country}</td>
                            <td>{record.commission}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            <Pagination pageNumbers={pageNumbers} currentPage={currentPage} paginate={paginate}/>
        </div>
    );
}

export default ClientsTable;