import React, { useEffect, useState } from 'react';
import CcAccounts from '../../components/user/CcAccounts';
import TopupAccounts from '../../components/user/TopupAccounts';
import UserNav from "../../components/user/UserNav";

const UserHome = () => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }) 

    return (
        <> 
            {loggedIn ? 
                <div>
                    <main className="main justify-content-center portal">
                        <div className='container'>
                            <TopupAccounts/>
                            <CcAccounts/>
                        </div>
                    </main>
                </div>
                :
                <div>You are not logged in.</div>
            }
        </>
    );
};

export default UserHome;
