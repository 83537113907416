import React, { useState, useEffect } from 'react';

// Client dropdown component
const ClientDropdown = (props: {client: number, setClient: any}) => {
    const [clients, setClients] = useState([{}]);
    const [refresh, setRefresh] = useState(false);
    const token = localStorage.getItem('token');
    
    // get ad accounts
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_ADMIN_URL}/getClients`,
        {
            method: 'GET',
            headers: {'Authorization': `Bearer ${token}`},
        })
        .then(response => response.json())
        .then(data => {
            if(data) {
                setClients(data)
            }
        }).catch((error) => {
            console.error('Error fetching data: ', error);
        });
        if(refresh) {
            setRefresh(false);
        }
    }, [token, refresh, props.client]); // Added props.client as a dependency

    const changeClient = (value: any) => {
        var clientId = parseInt(value)
        props.setClient(clientId);
    }

    return(
        <select className="form-select"  name="client-dropdown" placeholder="type" onChange={event => changeClient(event.target.value)} >
            <option disabled selected value="">Choose Client</option>
            {
                clients?.map((record: any) => (
                    record.ID === props.client ?
                        <option key={record.ID} selected value={record.ID}>{record.name}</option>
                        :
                        <option key={record.ID} value={record.ID}>{record.name}</option>
                ))
            
            }
        </select>
    )
};

export default ClientDropdown;