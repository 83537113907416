import React, { useState, useEffect } from 'react';
import formatDate from '../../util/date';

const Invoice = (props: {invoice: any}) => {
    const token = localStorage.getItem('token');
    const [items, setItems] = useState([{}]);
    const [itemsTitle, setItemsTitle] = useState("");
    const [itemIDTitle, setItemIDTitle] = useState("");
    const [itemDescTitle, setItemDescTitle] = useState("");
    useEffect(() => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/getInvoiceItems/${props.invoice.invoice_id}`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            )
            .then(response => response.json())
                .then(data => {
                    if(data){
                        setItems(data)
                    }
                })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            })


        }
        if(props.invoice.type === "TOPUP") {
            setItemsTitle("Topups");
            setItemIDTitle("Ad Account ID");
            setItemDescTitle("Account Name");
        }
        if(props.invoice.type === "MANUAL"){
            setItemsTitle("Items");
            setItemIDTitle("ID");
            setItemDescTitle("Description");
        }
        if(props.invoice.type === "CC") {
            setItemsTitle("Accounts");
            setItemIDTitle("Ad Account ID");
            setItemDescTitle("Ad Spend");
        }
    }, [props.invoice]);

    const downloadInvoice = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/downloadInvoice/${props.invoice.invoice_id}`,
            {
                method: "POST",
                headers: { Authorization: `Bearer ${token}` },
            }
        ).then((response) => {
                response.blob().then((blob) => {
             
                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);
                     
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = `invoice_${props.invoice.invoice_id}.pdf`;
                alink.click();
                })
            }).catch((error) => {
                console.error('Error posting data: ', error);
            });
    }

    return (
        <div>
            <div>
                <div>
                    <h3>ID: {props.invoice.invoice_id}</h3>
                    <h3>Type: {props.invoice.type}</h3>
                    <h3>Date: {formatDate(props.invoice.invoice_date)}</h3>
                    <h3>Currency: {props.invoice.currency}</h3>
				    <button className="btn btn-primary btn-block btn-large" onClick={downloadInvoice}>Download PDF</button>
                </div>
            </div>
            <div className='table-responsive'>
                <h4 className="white-title">{itemsTitle}</h4>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>{itemIDTitle}</th>
                            <th>{itemDescTitle}</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items ?
                            items.map((record: any, index: any) => (
                                <tr>
                                    <td>{props.invoice.type === "MANUAL" ? index + 1 : record.ad_account_id}</td>
                                    <td>{props.invoice.type === "MANUAL" ? record.description : record.name}</td>
                                    <td>{record.amount?.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                                </tr>
                            ))
                            :
                            <>No data retrieved</>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Invoice;