import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { createNoSubstitutionTemplateLiteral } from 'typescript';

type DataPoint = {
    total_spend: string;
    total_topups: string;
    remaining_funds: string;
    currency: string;
};

const RemainingFundsChart = () => {
    const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);
    const token = localStorage.getItem("token");

    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_API_ADMIN_URL}/getRemainingFunds`,
            {
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
            }
        )
        .then((response) => response.json())
        .then((data) => {
            if (data) {
                setDataPoints(data);
            }
        })
        .catch((error) => {
            console.error("Error fetching data: ", error);
        });
    }, []);

    return (
        <div>
            <h3>Remaining Funds</h3>
            {dataPoints.map((dp) => {
                const totalTopups = parseFloat(dp.total_topups);
                const totalSpend = parseFloat(dp.total_spend);
                const progressPercent = (totalSpend / totalTopups) * 100;

                return (
                    <div key={dp.currency} className="mb-3">
                        <h5 className="white-title">{dp.currency}</h5>
                        <ProgressBar now={progressPercent} label={`Remaining: ${totalTopups-totalSpend}`} />
                    </div>
                );
            })}
        </div>
    );
};

export default RemainingFundsChart;
