import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'; // Import Spinner from react-bootstrap
import Button from 'react-bootstrap/Button';

const AdAccountHistoryEditModal = (props: { show: boolean, handleClose: any, handleSubmit: any, record: any }) => {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const token = localStorage.getItem('token');

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        if (props.record.start_date && props.record.end_date) {
            setStartDate(props.record.start_date.split('T')[0]);
            if(props.record.end_date >= "0002-01-01") {
                setEndDate(props.record.end_date.split('T')[0]);
            }
        }
    }, [props.record.start_date, props.record.end_date]);

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(e.target.value);
    };

    const updateRecord = (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        const payload = {
            new_start_date: new Date(startDate).toISOString(),
            new_end_date: new Date(endDate).toISOString()
        };

        fetch(`${process.env.REACT_APP_API_ADMIN_URL}/clientAdAccount/${props.record.client_id}/${props.record.ad_account_id}/${props.record.type}`, {
            method: "PUT",
            headers: { 
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        })
        .then((response) => {
            if (response.ok) {
                setSuccess(true);
                setError(false);
                props.handleSubmit(); // Call the handleSubmit prop to handle success
            } else {
                throw new Error('Failed to update record');
            }
        })
        .catch((error) => {
            setError(true);
            setSuccess(false);
            console.error('Error updating record: ', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const deleteAssignment = () => {
        const payload = {
            start_date: props.record.start_date,
            client_id: props.record.client_id,
            ad_account_id: props.record.ad_account_id,
            type: props.record.type
        };
        fetch(`${process.env.REACT_APP_API_ADMIN_URL}/deleteClientAdAccount`, {
            method: "POST",
            headers: { 
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        })
        .then((response) => {
            if(response.ok) {
                props.handleClose();
            }
        })
        .catch((error) => {
            setError(true);
            setSuccess(false);
            console.error('Error posting data: ', error);
        });
    }

    return (
        <Modal show={props.show} onHide={props.handleClose} className="portal">
            <Modal.Header closeButton>
                <Modal.Title>Edit Ad Account Assignment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={updateRecord}>
                    <label>Start Date </label>
                    <input type="date" value={startDate} onChange={handleStartDateChange} />
                    <br />
                    <label>End Date </label>
                    <input type="date" value={endDate} onChange={handleEndDateChange} />
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                    </button>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger btn-large btn-block" onClick={deleteAssignment}>
                    Delete Assignment
                </button>
            </Modal.Footer>
            <Modal.Footer>
                {error &&
                    <div className="alert alert-danger" role="alert">
                        Error updating assignment
                    </div>
                }
                {success &&
                    <div className="alert alert-success" role="alert">
                        Successfully updated assignment 
                    </div>
                }
                <button className="btn btn-warning" onClick={props.handleClose}>
                    Close 
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default AdAccountHistoryEditModal;
