import React, { useState, useEffect } from 'react';
import '../../App.css';
import ClientAssignAdAccount from './ClientAssignAdAccount';
import ClientRemoveAdAccount from './ClientRemoveAdAccount';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import RefundModal from './RefundModal';

const User = (props: {user: any, goBack: any}) => {
    const token = localStorage.getItem('token');
    const [refresh, setRefresh] = useState(false);


    const deleteUser = () => {
        fetch(`${process.env.REACT_APP_API_ADMIN_URL}/deleteUser/${props.user.user_id}`,
            {
                method: "POST",
                headers: { Authorization: `Bearer ${token}` },
            }
        ).then((response) => {
            if(response.ok) {
                props.goBack()
            }
        }).catch((error) => {
            console.error('Error posting data: ', error);
        });
    }

    return (
        <div>
            <div className="col-12">
                <div>
                    <h3 className="white-title">Email: {props.user.email}</h3>
                    <button className="btn btn-danger" onClick={deleteUser}>Delete User</button>
                </div>
            </div>
        </div>
    );
};

export default User;