import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination';

const AdAccountRequests = (props: {setSelected: any}) => {
    const [requestsData, setRequestsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Adjust based on your preference
    type SortConfig = {
        key: keyof any | null;
        direction: 'ascending' | 'descending';
    };
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');

    useEffect(() => {
        if(token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/getAdAccountRequests`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            ).then(response => response.json())
            .then(data => {
                if(data) {
                    setRequestsData(data);
                    setFilteredData(data);
                    setLoading(false);
                }
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            })
            if(refresh) {
                setRefresh(false);
            }
        }
    }, [token, refresh]);

    const requestSort = (key: keyof any) => { 
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    } 
    
    useEffect(() => {
        let newData = [...requestsData];

        // Sort
        if (sortConfig.key !== null && requestsData.length > 0) {
            newData.sort((a, b) => {
                const aValue = a[sortConfig.key as keyof typeof a];
                const bValue = b[sortConfig.key as keyof typeof b];

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        // Search
        newData = newData.filter(
            (item: any) =>
                item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.client_name.toString().includes(searchQuery) ||
                item.bm_id.toString().includes(searchQuery) ||
                item.currency.toString().includes(searchQuery) ||
                item.timezone.toString().includes(searchQuery) ||
                item.id.toString().includes(searchQuery)

        );

        setFilteredData(newData);
    }, [requestsData, sortConfig, searchQuery]);

    // Calculate the current invoices to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages and generate an array of page numbers
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

    return(
        <div>
            <h3>Ad Account Requests</h3>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="table-responsive">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('id')}>ID</th>
                        <th onClick={() => requestSort('type')}>Type</th>
                        <th onClick={() => requestSort('client_name')}>Client Name</th>
                        <th onClick={() => requestSort('bm_id')}>BM ID</th>
                        <th onClick={() => requestSort('currency')}>Currency</th>
                        <th onClick={() => requestSort('timezone')}>Timezone</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems ?
                        currentItems.map((record: any) => (
                            <tr key={record.id}>
                                <td><button className="btn" onClick={event => props.setSelected(record)}>{record.id}</button></td>
                                <td>{record.type}</td>
                                <td>{record.client_name}</td>
                                <td>{record.bm_id}</td>
                                <td>{record.currency}</td>
                                <td>{record.timezone}</td>
                            </tr>
                        )) : 
                        <></>
                    }
                </tbody>
            </table>
            </div>
            <Pagination pageNumbers={pageNumbers} currentPage={currentPage} paginate={paginate}/>
        </div>
    )

}

export default AdAccountRequests;