import React, { useState, useEffect } from 'react';

const MaluCreditCardForm = () => {
    const token = localStorage.getItem('token');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        number: '',
        bank: '',
        currency: '',
        card_name: '',
        limit: '',
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const submitHandler = async (e: any) => {
        e.preventDefault();
        // Assuming you have an API endpoint to handle the POST request
        if(token) { 
            try {
                const response = await fetch(`${process.env.REACT_APP_API_ADMIN_URL}/createCreditCard`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
                    body: JSON.stringify(formData)
                }
            );

                if (response.ok) {
                    setSuccess(true);
                    setError(false);
                } else {
                    setSuccess(false);
                    setError(true);
                }
            } catch (error) {
            console.error('Error:', error);
            }
        }
    };
    
    useEffect(() => {
    }) 

    return (
        <form onSubmit={submitHandler}>
            <label className='white-title'>Number</label>
            <input type="number" name="number" value={formData.number} onChange={handleChange}/>
            <label className='white-title'>Bank</label>
            <input type="text" name="bank" value={formData.bank} onChange={handleChange}/>
            <label className='white-title'>Currency</label>
            <input type="text" name="currency" value={formData.currency} onChange={handleChange}/>
            <label className='white-title'>Card Name</label>
            <input type="text" name="card_name" value={formData.card_name} onChange={handleChange}/>
            <label className='white-title'>Limit</label>
            <input type="text" name="limit" value={formData.limit} onChange={handleChange}/>
            <button type="submit" className="btn btn-primary btn-large btn-block">Add New Credit Card</button>
			<br/>
			{error &&
				<div className="alert alert-danger" role="alert">
					Error: Failed to save credit card 
				</div>
			}
			{success &&
				<div className="alert alert-success" role="alert">
					Success: Saved credit card
				</div>
			}
        </form>
    );
};

export default MaluCreditCardForm;