import React, { useState, useEffect } from 'react';
import CsvUpload from '../../components/admin/CsvUpload';
import DailyTotalAdSpendsTable from '../../components/admin/DailyTotalAdSpendsTable';

const AdminAdSpends = () => {
    const [loggedIn, setLoggedIn] = useState(false);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }) 

    return (
        <>
            {loggedIn ?
                <div>
                    <main className="main d-flex justify-content-center">
                        <div className="container">
                            <CsvUpload/>
                            <DailyTotalAdSpendsTable/>
                        </div>
                    </main>
                </div>
                :
                <div>You are not logged in.</div>
            }
        </>
    );
};

export default AdminAdSpends;