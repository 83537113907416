import React, { useState, useEffect } from 'react';
import AdAccountDropdown from "./AdAccountDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ClientAssignAdAccount = (props: {clientId: any}) => {
    const [adAccount, setAdAccount] = useState("");
    const [startDate, setStartDate] = useState(() => {
        const now = new Date();
        return new Date(now.setHours(0, 0, 0, 0));
    });
    const [type, setType] = useState("TOPUP");
    const token = localStorage.getItem('token');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const submitHandler = () => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/addAdAccountToClient`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`},
                    body: JSON.stringify({
                        'ad_account_id': adAccount,
                        'client_id': props.clientId,
                        'type': type,
                        'start_date': startDate,
                    })
                }
            ).then(() => {
                setSuccess(true);
                setError(false);
            }).catch(() => {
                setError(true);
                setSuccess(false);
            })
        }
    }

    const handleDateChange = (date: Date) => {
        // Set the time to 00:00:00.000
        const newDate = new Date(date.setHours(0, 0, 0, 0));
        setStartDate(newDate);
    };

    return(
        <div>
            <h4 className="white-title">Assign Ad Account</h4>
            <AdAccountDropdown adAccount={adAccount} setAdAccount={setAdAccount} client={0}/>
            <br/>
            <select className="form-select"  name="type" placeholder="type" onChange={event => setType(event.target.value)} >
                <option disabled value="">Choose Type</option>
                <option value="TOPUP">Topup</option>
                <option value="CC">CC</option>
            </select>
            <label className='white-title'>Start Date </label>
            <br/>
            <DatePicker selected={startDate} onChange={(date: Date) => handleDateChange(date)} />
            <br/>
            <button className="btn btn-primary btn-large" onClick={submitHandler}>Assign Account</button>
			<h4 className="white-title">{success ? "Assigned account to client" : ""}{error ? "Failed to assign account to client": ""}</h4>
			<br/>
			{error &&
				<div className="alert alert-danger" role="alert">
					Error: Failed to assign ad account to client 
				</div>
			}
			{success &&
				<div className="alert alert-success" role="alert">
					Success: Assigned ad account to client
				</div>
			}
        </div>
    );
}

export default ClientAssignAdAccount;