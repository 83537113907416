import React, { useState, useEffect } from 'react';
import '../../App.css';
import ClientAssignAdAccount from './ClientAssignAdAccount';
import ClientRemoveAdAccount from './ClientRemoveAdAccount';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import RefundModal from './RefundModal';
import ClientForm from './ClientForm';
import { FaPencilAlt } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";
import formatDate from '../../util/date';

const Client = (props: { client: any }) => {
    const [totalsData, setTotalsData] = useState([{ total_topups: 0, total_spend: 0, cc_limit: 0 }]);
    const [topupData, setTopupData] = useState([{
        "ad_account_id": 0,
        "name": "-",
        "currency": "-",
        "total_topups": 0,
        "total_spend": 0,
    }]);
    const [refundCurrency, setRefundCurrency] = useState("");
    const [refundAmount, setRefundAmount] = useState(0);
    const token = localStorage.getItem('token');
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const [isFormCollapsed, setIsFormCollapsed] = useState(true); // State to manage form collapse

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (token) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/getClientTotalTopups/${props.client.ID}`,
                {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                }
            ).then(response => response.json())
                .then(data => {
                    if (data) {
                        setTotalsData(data)
                    }
                }).catch((error) => {
                    console.error('Error fetching data: ', error);
                })

            fetch(`${process.env.REACT_APP_API_BASE_URL}/getTopupAccountsOverview/${props.client.ID}`,
                {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                }
            )
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setTopupData(data)
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data: ', error);
                })
        }
    }, [token, refresh]);

    const updateCreditCardLimit = (malu_credit_card_id: string) => {
        // get limit and cast to HTMLInputElement to use .value
        const limit = (document.getElementById(`cc_id_${malu_credit_card_id}`) as HTMLInputElement).value
        fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/updateCcLimit/${malu_credit_card_id}/${limit}`,
            {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            }
        ).catch((error) => {
            console.error('Error fetching data: ', error);
        })
        setRefresh(true);
    };

    const initiateRefund = (currency: string) => {
        setRefundCurrency(currency);
        handleShow();
    }

    const submitRefund = async (e: any) => {
        e.preventDefault();
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_ADMIN_URL}/createRefundInvoice`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ "client_id": props.client.ID, "currency": refundCurrency, "amount": refundAmount }),
                }
            );

            if (response.ok) {
                setShow(false);
            } else {
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }

    return (
        <div>
            <div className="col-12">
                <div>
                    {isFormCollapsed && <h3>{props.client.name}</h3>}
                    <Button variant="outline-primary" onClick={() => setIsFormCollapsed(!isFormCollapsed)}>
                        {isFormCollapsed ? <><FaPencilAlt /></>: 'Close'}
                    </Button>
                    {!isFormCollapsed && <ClientForm client={props.client} />}
                </div>
            </div>
            <ClientAssignAdAccount clientId={props.client.ID} />
            <ClientRemoveAdAccount clientId={props.client.ID} />
            <div className="col-12 portal">
                <h3>Topup Accounts Overview</h3>
                <div className="table-responsive">
                    <table className="table totals-table">
                        <thead>
                            {totalsData ?
                                totalsData.map((record: any) => (
                                    <tr key={record.currency}>
                                        <td><b>{record.currency}</b></td>
                                        <td><b>Total Topup:</b> {record.total_topups.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                                        <td><b>CC Limit Total:</b> {record.cc_limit.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                                        <td></td>
                                        <td><b>Ad Spend Total:</b> {record.total_spend.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                                        <td></td>
                                        <td><b>Remaining Funds:</b> {(record.total_topups - record.total_spend).toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                                        <td>
                                            {record.total_topups - record.total_spend !== 0 ?
                                                <button className="btn btn-primary" onClick={event => initiateRefund(record.currency)}>Refund</button>
                                                : null
                                            }
                                        </td>
                                    </tr>
                                ))
                                :
                                <></>
                            }
                        </thead>
                    </table>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                <td>Account ID</td>
                                <td>Account Name</td>
                                <td>Status</td>
                                <td>Currency</td>
                                <td>Start Date</td>
                                <td>Spend</td>
                                <td>Credit Card Number</td>
                                <td>Credit Card Limit</td>
                                <td>Update Limit</td>
                            </tr>
                        </thead>
                        <tbody>
                            {topupData ?
                                topupData.map((record: any) => (
                                    <tr key={record.ad_account_id}>
                                        <td>{record.ad_account_id}</td>
                                        <td>{record.name}</td>
                                        <td>{record.status}</td>
                                        <td>{record.currency}</td>
                                        <td>{formatDate(record.start_date)}</td>
                                        <td className="table-number">{record.total_spend.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                                        <td>{record.credit_card_number}</td>
                                        <td className="table-number">{record.cc_limit?.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                                        <td>
                                            <input type="number" id={`cc_id_${record.malu_credit_card_id}`} disabled={record.credit_card_number === "" ? true : false} />
                                            {record.malu_credit_card_id !== 0 ?
                                                <button className="btn btn-primary" onClick={event => updateCreditCardLimit(record.malu_credit_card_id)}>Update</button>
                                                : null
                                            }
                                        </td>
                                    </tr>
                                ))
                                :
                                <>No data retrieved</>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <RefundModal show={show} setRefundAmount={setRefundAmount} handleClose={handleClose} handleSubmit={submitRefund} />
        </div>
    );
};

export default Client;