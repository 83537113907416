import React, { useEffect, useState } from 'react';
import formatDate from '../../util/date';
import Pagination from '../Pagination';

const ClientMonthlySpendRankings = () => {
    const [rankingsData, setRankingsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage, setClientsPerPage] = useState(50); // Adjust based on your preference
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const client = localStorage.getItem('client');
    const [headerTitles, setHeaderTitles] = useState([""]);

    useEffect(() => {
        const getLastFiveMonths = () => {
            const months = [];
            const today = new Date();

            for (let i = 4; i >= 0; i--) {
                const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
                const month = date.toLocaleString('default', { month: 'long' });
                const year = date.getFullYear();
                months.push(`${month} ${year}`);
            }

            return months;
        };

        setHeaderTitles(getLastFiveMonths());
    }, []);

    useEffect(() => {
        if (token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/getClientMonthlySpendRankings`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            }).then(response => response.json())
            .then(data => {
                if (data) {
                    setRankingsData(data);
                    setLoading(false);
                }
            }).catch((error) => {
                console.error('Error fetching data: ', error);
                setLoading(false);
            });
            if (refresh) {
                setRefresh(false);
            }
        }
    }, [token, refresh, client]);

    // Calculate the current invoices to display
    const indexOfLastClient = currentPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClients = rankingsData.slice(indexOfFirstClient, indexOfLastClient);

    // Calculate total pages and generate an array of page numbers
    const totalPages = Math.ceil(rankingsData.length / clientsPerPage);
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

    const chunkArray = (array: any, chunkSize: any) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    const currentClientsChunks = chunkArray(currentClients, 5);

    return (
        <div>
            <h3>Client Monthly Spend Rankings</h3>
            <div className="table-responsive">
            <table id="datatable" className="table table-hover">
                <thead>
                    <tr>
                        {headerTitles.map((title, index) => (
                            <th key={index} colSpan={3}>{title}</th>
                        ))}
                    </tr>
                    <tr className="second-header">
                        {headerTitles.map((title, index) => (
                            <>
                                <th key={index}>Client</th>
                                <th key={index}>Currency</th>
                                <th key={index}>Spend</th>
                            </>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentClientsChunks.map((chunk, chunkIndex) => (
                        <tr key={chunkIndex}>
                            {chunk.map((record: any, recordIndex: any) => (
                                <>
                                    <td key={recordIndex}>
                                        <button className="btn">{record.name}</button>
                                    </td>
                                    <td>
                                        {record.currency}
                                    </td>
                                    <td>
                                        {record.spend?.toLocaleString('de-DE', { minimumFractionDigits: 2 })}
                                    </td>
                                </>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            <Pagination pageNumbers={pageNumbers} currentPage={currentPage} paginate={paginate}/>
        </div>
    );
};

export default ClientMonthlySpendRankings;