import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination';

const AdAccountsTable = (props: {setSelected: any}) => {
    const [adAccountsData, setAdAccountsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100); // Adjust based on your preference
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState("");
    type SortConfig = {
        key: keyof any | null;
        direction: 'ascending' | 'descending';
    };
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');
    const token = localStorage.getItem('token');

    useEffect(() => {
        if(token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/getAdAccounts`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            ).then(response => response.json())
            .then(data => {
                if(data) {
                    setAdAccountsData(data);
                    setFilteredData(data);
                }
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            })
            if(refresh) {
                setRefresh(false);
            }
        }
    }, [token, refresh]);

    const toggleAvailable = () => {
        if(filter!=="available"){
            setFilter("available");
        } else {
            setFilter("");
        }
    }

    const toggleUnused = () => {
        if(filter!=="unused"){
            setFilter("unused");
        } else {
            setFilter("");
        }
    }
    
    const toggleMissing = () => {
        if(filter!=="missing"){
            setFilter("missing");
        } else {
            setFilter("");
        }
    }
    
    useEffect(() => {
        let newData = [...adAccountsData];

        // Sort
        if (sortConfig.key !== null && adAccountsData.length > 0) {
            newData.sort((a, b) => {
                const aValue = a[sortConfig.key as keyof typeof a];
                const bValue = b[sortConfig.key as keyof typeof b];

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        // Search
        newData = newData.filter(
            (item: any) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.client_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.timezone.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.bm_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.ad_account_id.toString().includes(searchQuery)

        );
        
        // available/unused
        newData = newData.filter(
            (record: any) => {
                if (filter === "available") {
                    return record.available === "1" && record.status !== "missing";
                } else if (filter === "unused") {
                    return record.state === "Unused" && record.status !== "missing";
                } else if (filter === "missing") {
                    return record.status === "missing";
                } else {
                    return record.status !== "missing"; // If filter criteria doesn't match, return all records
                }
            }
        )

        setFilteredData(newData);
    }, [adAccountsData, sortConfig, searchQuery, filter]);

    const requestSort = (key: keyof any) => { // Replace 'ClientData' with your data type
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    } 
    
    // Calculate the current invoices to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages and generate an array of page numbers
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

    return(
        <>
            <button className="btn btn-success btn-medium" onClick={toggleAvailable}>{filter === "available" ? "show all accounts" :"show available accounts"}</button> 
            &nbsp;<button className="btn btn-warning btn-medium" onClick={toggleUnused}>{filter === "unused" ? "show all accounts" :"show unused accounts"}</button>
            &nbsp;<button className="btn btn-danger btn-medium" onClick={toggleMissing}>{filter === "missing" ? "show all accounts" :"show missing accounts"}</button>
            <br/>
            <br/>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="table-responsive">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('ad_account_id')}>Ad Account ID</th>
                        <th onClick={() => requestSort('name')}>Name</th>
                        <th onClick={() => requestSort('client_name')}>Client Name</th>
                        <th onClick={() => requestSort('type')}>Type</th>
                        <th onClick={() => requestSort('status')}>Status</th>
                        <th onClick={() => requestSort('currency')}>Currency</th>
                        <th onClick={() => requestSort('timezone')}>Timezone</th>
                        <th onClick={() => requestSort('country')}>Country</th>
                        <th onClick={() => requestSort('country')}>Supplier BM-ID</th>
                    </tr>
                </thead>
                <tbody>
                {currentItems ?
                    currentItems
                        .map((record: any) => (
                            <tr key={record.ad_account_id}>
                                <td><button className="btn" onClick={event => props.setSelected(record)}>{record.ad_account_id}</button></td>
                                <td>{record.name}</td>
                                <td>{record.client_name}</td>
                                <td>{record.type}</td>
                                <td>{record.status}</td>
                                <td>{record.currency}</td>
                                <td>{record.timezone}</td>
                                <td>{record.country}</td>
                                <td>{record.bm_id}</td>
                            </tr>
                        )) : 
                    <></>
                }
                </tbody>
            </table>
            </div>
            <Pagination pageNumbers={pageNumbers} currentPage={currentPage} paginate={paginate}/>
        </>
    )

}

export default AdAccountsTable;