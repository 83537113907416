const formatDate = (dateString: string) => {
    if(dateString){
        // Use a regular expression to match the date part of the string
        const match = dateString.match(/^(\d{4}-\d{2}-\d{2})T/);
        if (match && match[1]) {
            return match[1];
        }
        throw new Error("Invalid date string format");
    }
};

export default formatDate;