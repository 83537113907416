import '../../App.css';
import React, { useEffect, useState } from 'react';


function AdSpendTracker(){
    const token = localStorage.getItem('token');
    const client = localStorage.getItem('client');
    const [ccData, setCcData] = useState([{
        "ad_account_id": 0, 
        "name": "-", 
        "currency": "-",
        "spend": 0
    }]);
    const [loading, setLoading] = useState(false);
    const [ccTotal, setCcTotal] = useState(0);

    useEffect(() => {
        if(token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_BASE_URL}/getCcDailyAdSpends/${client}`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            )
                .then(response => response.json())
                .then(data => {
                    setLoading(false);
                    if(data){
                        setCcData(data)
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data: ', error);
                })
        }

    }, [token, client]);

    useEffect(() => {
        let subCc = 0;
        ccData.forEach((record: any) => {
            subCc += parseFloat(record.spend);
        });
        setCcTotal(parseFloat(subCc.toFixed(2)));
    }, [ccData])

	return(
        <div className="with-footer">
            {loading ? (

                <div>Loading...</div>

            ) : (
            <>
            <h1 className="white-title">CC Accounts</h1>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>Account Name</th>
                        <th>Currency</th>
                        <th>Daily Ad Spend</th>
                    </tr>
                </thead>
                <tbody>
                    {ccData.map((record: any) => (
                    <tr key={record.ad_account_id}>
                        <td>{record.name}</td>
                        <td>{record.currency}</td>
                        <td>{record.spend.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                    </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={2}>Total</td>
                        <td>{ccTotal.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                    </tr>
                </tfoot>
            </table>
            </>)}
		</div>
	)

}

export default AdSpendTracker;