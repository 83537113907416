import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type DataPoint = {
    day: string;
    daily_total: number;
    currency: string;
};

type GroupedData = {
    [key: string]: { [currency: string]: number };
};

const DailyTotalAdSpendsBarChart: React.FC = () => {
    const token = localStorage.getItem('token');
    const [chartData, setChartData] = useState<ChartData<'bar', number[], string>>({
        labels: [],
        datasets: []
    });

    const [options, setOptions] = useState<ChartOptions<'bar'>>({
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: 'white' // Set x-axis label color to white
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: 'white' // Set x-axis label color to white
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: 'white' // Set legend label color to white
                }
            }
        }
    });

    useEffect(() => {
        // Fetch data from your API endpoint
        fetch(`${process.env.REACT_APP_API_ADMIN_URL}/getDailyTotalAdSpends`,
            {
                method: 'POST',
                headers: {'Authorization': `Bearer ${token}`},
                body: JSON.stringify({ "order": "asc", "start_date": "2023-11-11", "end_date": "" }), // Convert struct to JSON
            }
        )
        .then(response => response.json())
        .then((data: DataPoint[]) => {
            // Process and group the fetched data
            const groupedData: GroupedData = data.reduce((acc: GroupedData, item) => {
                acc[item.day] = acc[item.day] || {};
                acc[item.day][item.currency] = (acc[item.day][item.currency] || 0) + item.daily_total;
                return acc;
            }, {});

            const labels = Object.keys(groupedData);

            const datasets = Object.entries(groupedData[labels[0]]).map(([currency], index) => ({
                label: currency,
                data: labels.map(day => groupedData[day][currency] || 0),
                backgroundColor: currency === 'USD' ? 'rgba(75, 192, 192, 0.6)' : 'rgba(54, 162, 235, 0.6)'
            }));

            setChartData({
                labels,
                datasets
            });
        })
        .catch((error) => {
            console.error('Error fetching data: ', error);
        });
    }, []);

    return (
        <div>
            <h3>Daily Total Ad Spends</h3>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default DailyTotalAdSpendsBarChart;
