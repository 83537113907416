import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";
import logo from '../../assets/malu_logo.jpeg';
import '../../App.css';

const AdminNav = () => {
    const logout = async () => {
        localStorage.clear();
        await fetch(`${process.env.REACT_APP_API_AUTH_URL}/logout`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        });
    }

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" className="portal-nav">
            <Container>
                <Navbar.Brand as={Link} to="/admin/home">
                    <img src={logo} alt="Logo" width="30" height="30" className="d-inline-block align-top portal-nav-brand" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/admin/home">Home</Nav.Link>
                        <Nav.Link as={Link} to="/admin/invoices">Invoices</Nav.Link>
                        <Nav.Link as={Link} to="/admin/adspends">Ad Spend</Nav.Link>
                        <Nav.Link as={Link} to="/admin/clients">Clients</Nav.Link>
                        <Nav.Link as={Link} to="/admin/credit-cards">Credit Cards</Nav.Link>
                        <Nav.Link as={Link} to="/admin/ad-accounts">Ad Accounts</Nav.Link>
                        <Nav.Link as={Link} to="/admin/ad-account-requests">Account Requests</Nav.Link>
                        <Nav.Link as={Link} to="/admin/users">Users</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link as={Link} to="/login" onClick={logout}>Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default AdminNav;
