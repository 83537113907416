import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination';

const MaluCreditCardsTable = (props: {setSelected: any}) => {
    const [maluCreditCardsData, setMaluCreditCardsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [creditCardsPerPage, setCreditCardsPerPage] = useState(10); // Adjust based on your preference
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    type SortConfig = {
        key: keyof any | null;
        direction: 'ascending' | 'descending';
    };
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if(token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/getCreditCards`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            ).then(response => response.json())
            .then(data => {
                if(data) {
                    setMaluCreditCardsData(data);
                    setFilteredData(data);
                }
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            })
            if(refresh) {
                setRefresh(false);
            }
        }
    }, [token, refresh]);

    useEffect(() => {
        let newData = [...maluCreditCardsData];

        // Sort
        if (sortConfig.key !== null && maluCreditCardsData.length > 0) {
            newData.sort((a, b) => {
                const aValue = a[sortConfig.key as keyof typeof a];
                const bValue = b[sortConfig.key as keyof typeof b];

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        // Search
        newData = newData.filter(
            (item: any) =>
                item.bank.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.card_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.number.toString().includes(searchQuery) ||
                item.limit.toString().includes(searchQuery)

        );

        setFilteredData(newData);
    }, [maluCreditCardsData, sortConfig, searchQuery]);


    const requestSort = (key: keyof any) => { // Replace 'ClientData' with your data type
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    } 
    
    // Calculate the current invoices to display
    const indexOfLastCC = currentPage * creditCardsPerPage;
    const indexOfFirstCC = indexOfLastCC - creditCardsPerPage;
    const currentCreditCards = filteredData.slice(indexOfFirstCC, indexOfLastCC);

    // Calculate total pages and generate an array of page numbers
    const totalPages = Math.ceil(filteredData.length / creditCardsPerPage);
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);


    return(
        <div>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="table-responsive">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('number')}>Number</th>
                        <th onClick={() => requestSort('bank')}>Bank</th>
                        <th onClick={() => requestSort('currency')}>Currency</th>
                        <th onClick={() => requestSort('card_name')}>Card Name</th>
                        <th onClick={() => requestSort('limit')}>Limit</th>
                    </tr>
                </thead>
                <tbody>
                    {currentCreditCards ?
                        currentCreditCards.map((record: any) => (
                            <tr key={record.id}>
                                <td>{record.number}</td>
                                <td>{record.bank}</td>
                                <td>{record.currency}</td>
                                <td>{record.card_name}</td>
                                <td className="table-number">{record.limit?.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                            </tr>
                        )) : 
                        <></>
                    }
                </tbody>
            </table>
            </div>
            <Pagination pageNumbers={pageNumbers} currentPage={currentPage} paginate={paginate}/>
        </div>
    )

}

export default MaluCreditCardsTable;