import React, { useState, useEffect } from 'react';
import AdAccountDropdown from './AdAccountDropdown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AdAccountRequest = (props: {request: any}) => {
    const [adAccount, setAdAccount] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const token = localStorage.getItem('token');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const submitHandler = () => {
        if(token) {
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/requestAddAdAccountToClient`,
                {
                    method: 'POST',
                    headers: {'Authorization': `Bearer ${token}`},
                    body: JSON.stringify({
                        'ad_account_id': adAccount,
                        'client_id': props.request.client_id,
                        'type': props.request.type,
                        'request_id': props.request.id,
                        'start_date': startDate,
                    })
                }
            ).then(() => {
                setSuccess(true);
                setError(false);
            }).catch(() => {
                setError(true);
                setSuccess(false);
            })
        }
    }

    return (
        <div>
            <div className="col-12">
                <div>
                    <AdAccountDropdown adAccount={adAccount} setAdAccount={setAdAccount} client={0}/>
                    <br/>
                    <label className='white-title'>Start Date </label>
                    <br/>
                    <DatePicker selected={startDate} onChange={(date: any) => setStartDate(date)} />
                    <br/>
                    <button className="btn btn-primary btn-large btn-block" onClick={submitHandler}>Assign Account</button>
                    <br/>
                    {error &&
                        <div className="alert alert-danger" role="alert">
                            Error: Failed to assign ad account to client
                        </div>
                    }
                    {success &&
                        <div className="alert alert-success" role="alert">
                            Success: Assigned ad account to client
                        </div>
                    }
                </div>
            </div>
            <div className="col-12">
            </div>
        </div>
    );
};

export default AdAccountRequest;