import React, { useEffect, useState } from 'react';
import formatDate from '../../util/date';
import Pagination from '../Pagination';

const InvoicesTable = (props: {setSelected: any}) => {
    const [invoicesData, setInvoicesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState("");
    type SortConfig = {
        key: keyof any | null;
        direction: 'ascending' | 'descending';
    };
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');

    useEffect(() => {
        if(token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_ADMIN_URL}/getInvoices`,
                {
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${token}`},
                }
            ).then(response => response.json())
            .then(data => {
                if(data) {
                    setInvoicesData(data);
                    setFilteredData(data);
                    setLoading(false);
                }
            }).catch((error) => {
                console.error('Error fetching data: ', error);
            })
            if(refresh) {
                setRefresh(false);
            }
        }
    }, [token, refresh]);

    const approveInvoice = (invoice_id: string) => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/approveInvoice/${invoice_id}`,
            {
                method: 'GET',
                headers: {'Authorization': `Bearer ${token}`},
            }
        ).catch((error) => {
            console.error('Error fetching data: ', error);
        })
        setRefresh(true);
    };
    
    const paymentArrived = (invoice_id: string) => {
        // get transaction id and cast to HTMLInputElement to use .value
        const txId = (document.getElementById(`tx_id_inv_${invoice_id}`) as HTMLInputElement).value
        fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/invoiceArrived/${invoice_id}/${txId}`,
            {
                method: 'GET',
                headers: {'Authorization': `Bearer ${token}`},
            }
        ).catch((error) => {
            console.error('Error fetching data: ', error);
        })
        setRefresh(true);
    };
    
    const selectInvoice = (invoice: any) => {
        props.setSelected(invoice);
    }
    
    useEffect(() => {
        let newData = [...invoicesData];

        // Sort
        if (sortConfig.key !== null && invoicesData.length > 0) {
            newData.sort((a, b) => {
                const aValue = a[sortConfig.key as keyof typeof a];
                const bValue = b[sortConfig.key as keyof typeof b];

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        // Search
        newData = newData.filter(
            (item: any) =>
                item.client_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.amount.toString().includes(searchQuery) ||
                item.invoice_date.toString().includes(searchQuery) ||
                item.approved.toString().includes(searchQuery) ||
                item.arrived.toString().includes(searchQuery) ||
                item.invoice_id.toString().includes(searchQuery)
        );

        setFilteredData(newData);
    }, [invoicesData, sortConfig, searchQuery]);

    const requestSort = (key: keyof any) => { 
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    } 

  return (
        <div>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="table-responsive">
            <table id="datatable" className="table table-hover" >
                <thead>
                        <tr>
                            <th onClick={() => requestSort('invoice_id')}>ID</th>
                            <th onClick={() => requestSort('client_name')}>Client</th>
                            <th onClick={() => requestSort('type')}>Type</th>
                            <th onClick={() => requestSort('amount')}>Amount</th>
                            <th onClick={() => requestSort('currency')}>Currency</th>
                            <th onClick={() => requestSort('created_at')}>Date</th>
                            <th onClick={() => requestSort('approved')}>Approved</th>
                            <th onClick={() => requestSort('arrived')}>Arrived</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData ?
                            filteredData.map((record: any) => (
                                <tr key={record.invoice_id}>
                                    <td><button className="btn" onClick={event => selectInvoice(record)}>{record.invoice_id}</button></td>
                                    <td>{record.client_name}</td>
                                    <td>{record.type}</td>
                                    <td>{record.amount?.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
                                    <td>{record.currency}</td>
                                    <td>{formatDate(record.invoice_date)}</td>
                                    <td>
                                        {record.approved === 0 && record.type === "TOPUP" ? 
                                            <button className="btn btn-primary" onClick={event => approveInvoice(record.invoice_id)}>Approve</button>
                                            :
                                            <>Approved</>
                                        }
                                    </td>
                                    <td>
                                        {record.arrived === 0 ? 
                                            <>
                                                <input type="text" id={`tx_id_inv_${record.invoice_id}`}></input>
                                            </>
                                            :
                                            <>Transaction ID: </>
                                        }
                                    </td>
                                    <td>
                                        {record.arrived === 0 ? 
                                            <>
                                                <button className="btn btn-primary" onClick={event => paymentArrived(record.invoice_id)}>Arrived</button>
                                            </>
                                            :
                                            <>{record.transaction_id}</>
                                        }
                                    </td>
                                </tr>
                            )) 
                        : <>No data retrieved</>}
                    </tbody>
            </table>
            </div>
        </div>
  );
};

export default InvoicesTable;