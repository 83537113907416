import React, { useEffect, useState } from 'react';
import formatDate from '../../util/date';
import Pagination from '../Pagination';

const InvoicesTable = (props: { setSelected: any }) => {
    const [invoicesData, setInvoiceData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [invoicesPerPage, setInvoicesPerPage] = useState(10); // Adjust based on your preference
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const client = localStorage.getItem('client');
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState("");
    type SortConfig = {
        key: keyof any | null;
        direction: 'ascending' | 'descending';
    };
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (token) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_BASE_URL}/getInvoices/${client}`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            }).then(response => response.json())
            .then(data => {
                if (data) {
                    setInvoiceData(data);
                    setLoading(false);
                }
            }).catch((error) => {
                console.error('Error fetching data: ', error);
                setLoading(false);
            });
            if (refresh) {
                setRefresh(false);
            }
        }
    }, [token, refresh, client]);

    const selectInvoice = (invoice: any) => {
        props.setSelected(invoice);
    };

    // Calculate the current invoices to display
    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    const currentInvoices = filteredData.slice(indexOfFirstInvoice, indexOfLastInvoice);

    // Calculate total pages and generate an array of page numbers
    const totalPages = Math.ceil(filteredData.length / invoicesPerPage);
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

    useEffect(() => {
        let newData = [...invoicesData];

        // Sort
        if (sortConfig.key !== null && invoicesData.length > 0) {
            newData.sort((a, b) => {
                const aValue = a[sortConfig.key as keyof typeof a];
                const bValue = b[sortConfig.key as keyof typeof b];

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        // Search
        newData = newData.filter(
            (item: any) =>
                item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.amount.toString().includes(searchQuery) ||
                item.invoice_date.toString().includes(searchQuery)
        );

        setFilteredData(newData);
    }, [invoicesData, sortConfig, searchQuery]);

    const requestSort = (key: keyof any) => { // Replace 'ClientData' with your data type
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    } 

    return (
        <div>
            <h3>Invoices</h3>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="table-responsive">
            <table id="datatable" className="table table-hover">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('invoice_id')}>ID</th>
                        <th onClick={() => requestSort('type')}>Type</th>
                        <th onClick={() => requestSort('amount')}>Amount</th>
                        <th onClick={() => requestSort('currency')}>Currency</th>
                        <th onClick={() => requestSort('invoice_date')}>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {currentInvoices.map((record: any, index: any) => (
                        <tr key={index}>
                            <td><button className="btn" onClick={() => selectInvoice(record)}>{record.invoice_id}</button></td>
                            <td>{record.type}</td>
                            <td>{record.amount?.toLocaleString('de-DE', { minimumFractionDigits: 2})}</td>
                            <td>{record.currency}</td>
                            <td>{formatDate(record.invoice_date)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            <Pagination pageNumbers={pageNumbers} currentPage={currentPage} paginate={paginate}/>
        </div>
    );
};

export default InvoicesTable;